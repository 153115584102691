import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import LoaderButton from "../components/LoaderButton";
import * as CONSTANTS from "../constants";

const Staffs = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [isLoadingMore, setLoadingMore] = useState(false);
  const [masterData, setMasterData] = useState(null);
  const [staffs, setStaffs] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);

  useEffect(() => {
    if (history.action === 'PUSH' || history.action === 'REPLACE') {
      startup();
    }

    if (history.action === 'POP') {
      // console.log(localStorage.getItem("BUMA_LAST_KEY") + " : " + history.location.key)
      if (localStorage.getItem("BUMA_LAST_KEY")===history.location.key) {
        // console.log("should reset");
        startup();
        return;
      }

      // console.log("old values");

      let ls = localStorage.getItem("BUMA_" + history.location.key);
      // console.log(ls);
      if (ls) {
        let prevData = JSON.parse(ls);
        setMasterData(prevData.masterData);
        setStaffs(prevData.staffs);
        setLastEvaluatedKey(prevData.lastEvaluatedKey);
      }
    }

    return () => {};
  }, [])

  useEffect(() => {
    localStorage.setItem("BUMA_" + location.key, JSON.stringify({
      masterData: masterData,
      staffs: staffs,
      lastEvaluatedKey: lastEvaluatedKey
    }));

    return () => {
    };
  }, [masterData, staffs, lastEvaluatedKey])

  async function startup(isLoadMore = false) {
    if (isLoadMore) setLoadingMore(true);
    else setLoading(true);

    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();

    if (!masterData) {
      let masterParams = {
        method: 'get',
        url: CONSTANTS.API_PATH + '/master_data',
        headers: {
          Authorization: "Bearer " + jwt
        }
      };
      const masterResponse = await axios(masterParams);
      setMasterData(masterResponse.data);
    }

    let params = {
      method: 'get',
      url: CONSTANTS.API_PATH + '/staffs',
      headers: {
        Authorization: "Bearer " + jwt
      }
    };
    if (lastEvaluatedKey) params.params = {
      last_evaluated_key: JSON.stringify(lastEvaluatedKey)
    }
    const response = await axios(params);

    // console.log(JSON.stringify(response.data));
    setStaffs(prevState => [...prevState, ...response.data.results]);
    if (response.data.last_evaluated_key) setLastEvaluatedKey(response.data.last_evaluated_key);
    else setLastEvaluatedKey(null);

    if (isLoadMore) setLoadingMore(false);
    else setLoading(false);
  }


  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Staffs</h1>
        <Link to="/staffs/search" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-search fa-sm text-white-50"></i> Search</Link>
      </div>

      <div className="card shadow mb-4">
        <div className="card-body">
          {isLoading ?
            <>
              <div style={{ height: 500 }}>
                <FontAwesomeIcon className="text-primary initialSpinner" icon={faSpinner} size="3x" spin />
              </div>
            </>
            :
            <div className="table-responsive">
              <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>NIK</th>
                    <th>Job Title</th>
                    <th>Site</th>
                    <th>Email</th>
                    <th className="fit"></th>
                  </tr>
                </thead>
                <tbody>
                  {staffs && staffs.map((item, index) => (
                    <tr key={index} className={("is_disabled" in item) && item.is_disabled && "text-danger"}>
                      <td>{item.name}{("is_disabled" in item) && item.is_disabled && " (Disabled)"}</td>
                      <td>{item.preferred_username}</td>
                      <td>{masterData.job_titles[item["custom:job_title"]]}</td>
                      <td>{masterData.sites[item["custom:site"]]}</td>
                      <td>{item.email}</td>
                      <td className="fit">
                        <Link to={`/staffs/${item.id}`} className="d-none d-sm-inline-block btn btn-sm btn-secondary shadow-sm">
                          <i className="fas fa-eye fa-sm text-white-50"></i> View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          }

          {lastEvaluatedKey &&
            <div className="d-grid">
              <LoaderButton
                onClick={() => startup(true)}
                bssize="large"
                isLoading={isLoadingMore}
              >
                Load More
              </LoaderButton>
            </div>
          }

        </div>
      </div>

    </div>

  )
}

export default Staffs;
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppContext } from "./libs/contextLib";

export default function UnauthenticatedRoute({ children, ...rest }) {
  const { isAuthenticated, userLevel } = useAppContext();
  return (
    <div id="content-wrapper" className="d-flex flex-column min-vh-100">
      <div id="content min-vh-100">
        <Route {...rest}>
          {!isAuthenticated ? (
            children
          ) : (
            userLevel >= 100 ?
              <Redirect to="/dashboard" />
            :
              <Redirect to="/launch-training" />
          )}
        </Route>
      </div>
    </div>
  );
}
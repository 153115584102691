import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Button, Modal } from "react-bootstrap";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from "../libs/contextLib";
import LoaderButton from "../components/LoaderButton";
import * as CONSTANTS from "../constants";

const StaffProfile = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { showNotification } = useAppContext();

  let { userSub } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [isLoadingMore, setLoadingMore] = useState(false);
  const [isDisableShow, setDisableShow] = useState(false);
  const [isDisabling, setDisabling] = useState(false);
  const [isDeleteShow, setDeleteShow] = useState(false);

  const [masterData, setMasterData] = useState(null);
  const [staffProfile, setStaffProfile] = useState(null);
  const [trainingResults, setTrainingResults] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);

  useEffect(() => {
    if (history.action === 'PUSH' || history.action === 'REPLACE') {
      startup();
    }

    if (history.action === 'POP') {
      if (localStorage.getItem("BUMA_LAST_KEY") === history.location.key) {
        startup();
        return;
      }

      let ls = localStorage.getItem("BUMA_" + history.location.key);
      // console.log(ls);
      if (ls) {
        let prevData = JSON.parse(ls);
        setMasterData(prevData.masterData);
        setStaffProfile(prevData.staffProfile);
        setTrainingResults(prevData.trainingResults);
        setLastEvaluatedKey(prevData.lastEvaluatedKey);
      }
    }

    return () => { };
  }, [])

  useEffect(() => {
    localStorage.setItem("BUMA_" + location.key, JSON.stringify({
      masterData: masterData,
      staffProfile: staffProfile,
      trainingResults: trainingResults,
      lastEvaluatedKey: lastEvaluatedKey
    }));

    return () => {
    };
  }, [masterData, staffProfile, trainingResults, lastEvaluatedKey])

  async function startup(isLoadMore = false) {
    if (isLoadMore) setLoadingMore(true);
    else setLoading(true);

    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();

    if (!masterData) {
      let masterParams = {
        method: 'get',
        url: CONSTANTS.API_PATH + '/master_data',
        headers: {
          Authorization: "Bearer " + jwt
        }
      };
      const masterResponse = await axios(masterParams);
      setMasterData(masterResponse.data);
    }

    if (!staffProfile) {
      let staffProfileParams = {
        method: 'get',
        url: CONSTANTS.API_PATH + '/staff_profile',
        headers: {
          Authorization: "Bearer " + jwt
        },
        params: {
          sub: userSub
        }
      };
      const staffProfileResponse = await axios(staffProfileParams);
      setStaffProfile(staffProfileResponse.data.staff);
    }

    let params = {
      method: 'get',
      url: CONSTANTS.API_PATH + '/staff_training_results',
      headers: {
        Authorization: "Bearer " + jwt
      },
      params: {
        userSub: userSub
      }
    };
    if (lastEvaluatedKey) params.params.last_evaluated_key = JSON.stringify(lastEvaluatedKey);

    const response = await axios(params);

    // console.log(JSON.stringify(response.data));
    setTrainingResults(prevState => [...prevState, ...response.data.results]);
    if (response.data.last_evaluated_key) setLastEvaluatedKey(response.data.last_evaluated_key);
    else setLastEvaluatedKey(null);

    if (isLoadMore) setLoadingMore(false);
    else setLoading(false);
  }

  async function disableUser() {
    setDisabling(true);

    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();

    try {
      await axios({
        method: 'post',
        url: CONSTANTS.API_PATH + '/disable_user',
        headers: {
          Authorization: "Bearer " + jwt
        },
        data: {
          sub: userSub
        }
      });

      setStaffProfile(prevState => ({
        ...prevState,
        is_disabled: true
      }))

      showNotification(1, "User has been disabled!");
    } catch (error) {
      showNotification(0, error.message);
    }

    setDisableShow(false);
    setDisabling(false);
  }

  async function enableUser() {
    setDisabling(true);

    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();

    try {
      await axios({
        method: 'post',
        url: CONSTANTS.API_PATH + '/enable_user',
        headers: {
          Authorization: "Bearer " + jwt
        },
        data: {
          sub: userSub
        }
      });

      setStaffProfile(prevState => ({
        ...prevState,
        is_disabled: false
      }))

      showNotification(1, "User has been enabled!");
    } catch (error) {
      showNotification(0, error.message);
    }

    setDisableShow(false);
    setDisabling(false);
  }

  async function deleteUser() {
    setDisabling(true);

    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();

    try {
      await axios({
        method: 'post',
        url: CONSTANTS.API_PATH + '/delete_user',
        headers: {
          Authorization: "Bearer " + jwt
        },
        data: {
          sub: userSub
        }
      });

      showNotification(1, "User has been deleted!");
      setDisableShow(false);
      setDisabling(false);
      history.push("/staffs");
    } catch (error) {
      showNotification(0, error.message);

      setDisableShow(false);
      setDisabling(false);
    }
  }

  return (
    <div className="container-fluid">

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Staff Profile{staffProfile && ("is_disabled" in staffProfile) && staffProfile.is_disabled && <span className="text-danger"> (Disabled)</span>}</h1>
        <Button onClick={() => setDeleteShow(true)} className="btn-sm btn-danger shadow-sm"><i className="fas fa-de fa-trash text-white-50"></i> Delete User</Button>
      </div>

      {staffProfile &&
        <div className="card shadow mb-4">
          <div className="card-body">
            <b>Name:</b> {staffProfile.name}
            <br />
            <b>Email:</b> {staffProfile.email}
            <br />
            <b>NIK:</b> {staffProfile.preferred_username}
            <br />
            <b>Site:</b> {masterData.sites[staffProfile["custom:site"]]}
            <br />
            <b>Job Title:</b> {masterData.job_titles[staffProfile["custom:job_title"]]}
            <br />
            <br />
            {staffProfile && ("is_disabled" in staffProfile) && staffProfile.is_disabled ?
              <Button onClick={() => enableUser()} className="d-none d-sm-inline-block btn-sm btn-primary shadow-sm"><i className="fas fa-de fa-user-plus text-white-50"></i> Enable User</Button>
            :
              <Button onClick={() => setDisableShow(true)} className="d-none d-sm-inline-block btn-sm btn-warning shadow-sm"><i className="fas fa-de fa-user-times text-white-50"></i> Disable User</Button>
            }
          </div>
        </div>
      }

      <div className="card shadow mb-4">
        <div className="card-body">
          {isLoading ?
            <>
              <div style={{ height: 500 }}>
                <FontAwesomeIcon className="text-primary initialSpinner" icon={faSpinner} size="3x" spin />
              </div>
            </>
            :
            <>
              <h4 className="text-center mb-4">Training Records</h4>
              <div className="table-responsive">
                <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>Mode</th>
                      <th>Target</th>
                      <th>Module</th>
                      <th>Percentage</th>
                      <th>Duration</th>
                      {/* <th>Site</th> */}
                      <th>Date & Time</th>
                      <th className="fit"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {trainingResults && trainingResults.map((item, index) => (
                      <tr key={index}>
                        <td>{CONSTANTS.TRAINING_MODES[item.mode].name}</td>
                        <td>{CONSTANTS.TRAINING_TARGETS[item.target - 1].name}</td>
                        <td>{CONSTANTS.TRAINING_MODULES[item.module].name}</td>
                        <td className={item.percentage < 100 ? 'text-danger' : 'text-success'}>{item.percentage}%</td>
                        <td>{item.duration}</td>
                        {/* <td>{item["custom:site"]}</td> */}
                        <td>{moment(item.timestamp * 1000).add(7, 'hours').format("DD/MM/YYYY HH:mm:ss")}</td>
                        <td className="fit">
                          <Link to={`/staff-training-results/${item.sub}/${item.id}`} className="d-none d-sm-inline-block btn btn-sm btn-secondary shadow-sm">
                            <i className="fas fa-eye fa-sm text-white-50"></i> View
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          }

          {lastEvaluatedKey &&
            <div className="d-grid">
              <LoaderButton
                onClick={() => startup(true)}
                bssize="large"
                isLoading={isLoadingMore}
              >
                Load More
              </LoaderButton>
            </div>
          }

        </div>
      </div>


      <Modal
        show={isDisableShow}
        onHide={() => setDisableShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Disable</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>This person will no longer be able to login and do training in this portal once you disabled the account. You may enable this account again later. Are you sure you want to disable this user?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDisableShow(false)}>Cancel</Button>
          <Button variant="danger" onClick={() => disableUser()}>Disable</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isDeleteShow}
        onHide={() => setDeleteShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h3 className="text-center text-danger">WARNING!</h3>
          <p>This person will no longer be able to login and do training in this portal once you deleted the account. This action is NOT REVERSIBLE. Are you sure you want to delete this user?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteShow(false)}>Cancel</Button>
          <Button variant="danger" onClick={() => deleteUser()}>DELETE</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isDisabling}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Body>
              <div style={{ height: 200 }}>
                <FontAwesomeIcon className="text-primary initialSpinner" icon={faSpinner} size="3x" spin />
              </div>
        </Modal.Body>
      </Modal>

    </div>

  )
}

export default StaffProfile;
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import LoaderButton from "../components/LoaderButton";
import * as CONSTANTS from "../constants";

const StaffTrainingResults = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [isLoadingMore, setLoadingMore] = useState(false);
  const [trainingResults, setTrainingResults] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);

  useEffect(() => {
    // console.log(history.action + " - " + history.location.key);
    if (history.action === 'PUSH' || history.action === 'REPLACE') {
      startup();
    }

    if (history.action === 'POP') {
      if (localStorage.getItem("BUMA_LAST_KEY")===history.location.key) {
        startup();
        return;
      }

      let ls = localStorage.getItem("BUMA_" + history.location.key);
      // console.log(ls);
      if (ls) {
        let prevData = JSON.parse(ls);
        setTrainingResults(prevData.trainingResults);
        setLastEvaluatedKey(prevData.lastEvaluatedKey);
      }
    }

    return () => {};
  }, [])

  useEffect(() => {
    localStorage.setItem("BUMA_" + location.key, JSON.stringify({
      trainingResults: trainingResults,
      lastEvaluatedKey: lastEvaluatedKey
    }));

    return () => {
    };
  }, [trainingResults, lastEvaluatedKey])

  async function startup(isLoadMore=false) {
    if (isLoadMore) setLoadingMore(true);
    else setLoading(true);

    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();

    let params = {
      method: 'get',
      url: CONSTANTS.API_PATH + '/staff_training_results',
      headers: { 
        Authorization: "Bearer " + jwt
      }
    };
    if (lastEvaluatedKey) params.params = {
      last_evaluated_key: JSON.stringify(lastEvaluatedKey)
    };
    const response = await axios(params);
  
    // console.log(JSON.stringify(response.data));
    setTrainingResults(prevState => [...prevState, ...response.data.results]);
    if (response.data.last_evaluated_key) setLastEvaluatedKey(response.data.last_evaluated_key);
    else setLastEvaluatedKey(null);

    if (isLoadMore) setLoadingMore(false);
    else setLoading(false);
  }

  return (
    <div className="container-fluid">
      
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Training Results</h1>
      </div>

          <div className="card shadow mb-4">
            <div className="card-body">
              {isLoading ?
                <>
                  <div style={{height: 500}}>
                    <FontAwesomeIcon className="text-primary initialSpinner" icon={faSpinner} size="3x" spin/>
                  </div>
                </>
              :
                <div className="table-responsive">
                  <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>NIK</th>
                        <th>Mode</th>
                        <th>Target</th>
                        <th>Module</th>
                        <th>Percentage</th>
                        <th>Duration</th>
                        {/* <th>Site</th> */}
                        <th>Date & Time</th>
                        <th className="fit"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {trainingResults && trainingResults.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.preferred_username}</td>
                          <td>{CONSTANTS.TRAINING_MODES[item.mode].name}</td>
                          <td>{CONSTANTS.TRAINING_TARGETS[item.target-1].name}</td>
                          <td>{CONSTANTS.TRAINING_MODULES[item.module].name}</td>
                          <td className={item.percentage<100 ? 'text-danger' : 'text-success'}>{item.percentage}%</td>
                          <td>{item.duration}</td>
                          {/* <td>{item["custom:site"]}</td> */}
                          <td>{moment(item.timestamp*1000).tz("UTC").add(7, 'hours').format("DD/MM/YYYY HH:mm:ss")}</td>
                          <td className="fit">
                            <Link to={`/staff-training-results/${item.sub}/${item.id}`} className="d-none d-sm-inline-block btn btn-sm btn-secondary shadow-sm">
                              <i className="fas fa-eye fa-sm text-white-50"></i> View
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              }

              {lastEvaluatedKey &&
                <div className="d-grid">
                  <LoaderButton
                    onClick={() => startup(true)}
                    bssize="large"
                    isLoading={isLoadingMore}
                  >
                    Load More
                  </LoaderButton>
                </div>
              }

            </div>
          </div>

    </div>

  )
}

export default StaffTrainingResults;
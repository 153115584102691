import React from 'react'
import { Link, useLocation } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";

const SidebarAdmin = (props) => {
const { isSidebarExpanded, sidebarToggle } = useAppContext();

  const location = useLocation();

  return (
      <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${!isSidebarExpanded && "toggled"}`} id="accordionSidebar">

      <span className="sidebar-brand d-flex align-items-center justify-content-center">
          <div className="sidebar-brand-icon rotate-n-15">
              <i className="fas fa-vr-cardboard"></i>
          </div>
          <div className="sidebar-brand-text mx-2">VR Training Portal</div>
      </span>

      <hr className="sidebar-divider my-0" />

      <li className={`${location.pathname.startsWith("/training-results") ? "active" : ""} nav-item`}>
          <Link className="nav-link" to="/training-results">
              <i className="fas fa-fw fa-chart-bar"></i>
              <span>My Training Results</span>
          </Link>
      </li>

      <li className={`${location.pathname.startsWith("/launch-training") ? "active" : ""} nav-item`}>
          <Link className="nav-link" to="/launch-training">
              <i className="fas fa-fw fa-vr-cardboard"></i>
              <span>Launch Training</span>
          </Link>
      </li>

      <hr className="sidebar-divider my-0" />

      <div className="text-center d-none d-md-inline mt-4">
        <button onClick={sidebarToggle} className="rounded-circle border-0" id="sidebarToggle"></button>
      </div>

    </ul>
  )
}

export default SidebarAdmin;
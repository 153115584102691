import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { useFormFields } from "../libs/hooksLib";
import { CForm, CFormFloating, CFormInput, CFormLabel } from '@coreui/react';
import { useAppContext } from "../libs/contextLib";
import LoaderButton from "../components/LoaderButton";
import * as CONSTANTS from "../constants";

const ChangeEmail = (props) => {
  const { userHasProfile, showNotification } = useAppContext();

  const oldPasswordForm = useRef(null);
  const newPasswordForm = useRef(null);
  const confirmPasswordForm = useRef(null);

  const [formValidated, setFormValidated] = useState(false);

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    code: ""
  });
  const [isLoading, setLoading] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  useEffect(() => {
    return () => {};
  }, []);

  async function submit(e) {
    e.preventDefault()
    e.stopPropagation()

    const form = e.currentTarget
    setFormValidated(true);
    if (form.checkValidity() === false) {
      return;
    }

    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { email: fields.email });

      let res = await Auth.currentSession();
      let idToken = res.getIdToken();
      let jwt = idToken.getJwtToken();
      await axios({
        method: 'post',
        url: CONSTANTS.API_PATH + '/edit_profile',
        headers: {
          Authorization: "Bearer " + jwt
        },
        data: {
          email: fields.email
        }
      });

      setCodeSent(true);
    } catch (error) {
      showNotification(0, error.message);
    }
    setLoading(false);
  }

  async function submitConfirmation(e) {
    e.preventDefault()
    e.stopPropagation()

    setLoading(true);
    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", fields.code);

      const updatedUser = await Auth.currentUserInfo();
      userHasProfile(updatedUser);

      handleFieldChange({target: {
        id: "UPDATE_ALL",
        value: {
          email: "",
          code: ""
        }
      }});

      setCodeSent(false);
      setFormValidated(false);
      showNotification(1, "Email updated successfully!");
    } catch (error) {
      showNotification(0, error.message);
    }
    setLoading(false);
  }

  function renderUpdateForm() {
    return (
      <CForm
        className="needs-validation d-grid"
        noValidate
        validated={formValidated}
        onSubmit={submit}
      >
        <CFormFloating>
          <CFormInput
            className="mb-4"
            type="email"
            id="email"
            placeholder="e.g. mail@company.com"
            value={fields.email}
            onChange={handleFieldChange}
            pattern="[a-z0-9._%+-]+@([a-z0-9.-]+\.[a-z]{2,})$"
            disabled={isLoading}
            autoFocus
            required />
          <CFormLabel htmlFor="confirmPassword">New Email</CFormLabel>
        </CFormFloating>
        <LoaderButton
          type="submit"
          bssize="large"
          isLoading={isLoading}
        >
          Update Email
        </LoaderButton>
      </CForm>
    );
  }

  function renderConfirmationForm() {
    return (
      <CForm
        className="needs-validation d-grid"
        onSubmit={submitConfirmation}
      >
        <CFormFloating>
          <CFormInput
            type="text"
            id="code"
            placeholder="******"
            value={fields.code}
            onChange={handleFieldChange}
            disabled={isLoading}
            autoComplete="off"
            autoFocus
            required />
          <CFormLabel htmlFor="confirmPassword">Verification Code</CFormLabel>
        </CFormFloating>
        <CFormLabel className="mb-4">
          Please check your email ({fields.email}) for the verification code.
        </CFormLabel>
        <LoaderButton
          type="submit"
          bssize="large"
          isLoading={isLoading}
        >
          Submit
        </LoaderButton>
      </CForm>
    );
  }

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Change Email</h1>
      </div>

      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 col-12">
              {!codeSent ? renderUpdateForm() : renderConfirmationForm()}
            </div>
          </div>
        </div>
      </div>

    </div>

  )
}

export default ChangeEmail;
import React from 'react'
import PropTypes from 'prop-types'
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";

const Header = ({
  userName,
  userEmail=null,
  handleLogoutModalOpen,
  ...props
}) => {
  const { sidebarToggle } = useAppContext();

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <button onClick={sidebarToggle} id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
        <i className="fa fa-bars"></i>
      </button>

      <img height="30" src="/img/buma_logo.png" alt="BUMA" />

      <ul className="navbar-nav ml-auto">

        <li className="nav-item dropdown no-arrow">
          <Button className="nav-link dropdown-toggle" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" variant="link">
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{userName}{userEmail && (" (" + userEmail + ")")}</span>
            <i className="fas fa-user rounded-circle"></i>
          </Button>
          <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            {/* <a className="dropdown-item" href="<?php echo site_url().'trainer/change_email'; ?>">
              <i className="fas fa-envelope fa-sm fa-fw mr-2 text-gray-400"></i>
              Change Email
            </a> */}
            {/* <a className="dropdown-item" href="<?php echo site_url().'trainer/change_password'; ?>">
              <i className="fas fa-lock fa-sm fa-fw mr-2 text-gray-400"></i>
              Change Password
            </a> */}
            <Link className="dropdown-item" to="/edit-profile">
              <i className="fas fa-user-edit fa-sm fa-fw mr-2 text-gray-400"></i>
              Edit Profile
            </Link>
            <Link className="dropdown-item" to="/change-email">
              <i className="fas fa-envelope fa-sm fa-fw mr-2 text-gray-400"></i>
              Change Email
            </Link>
            <Link className="dropdown-item" to="/change-password">
              <i className="fas fa-lock fa-sm fa-fw mr-2 text-gray-400"></i>
              Change Password
            </Link>
            <Link className="dropdown-item" to="#" onClick={() => handleLogoutModalOpen()}>
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
            </Link>
          </div>
        </li>

      </ul>

    </nav>
  )
}

Header.propTypes = {
  userName: PropTypes.string.isRequired,
  handleLogoutModalOpen: PropTypes.func.isRequired
}

export default Header;
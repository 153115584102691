import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { CForm, CFormFloating, CFormInput, CFormLabel } from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSearch } from '@fortawesome/free-solid-svg-icons';
import LoaderButton from "../components/LoaderButton";
import * as CONSTANTS from "../constants";

const Staffs = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { showNotification } = useAppContext();

  const [isLoading, setLoading] = useState(false);

  const [masterData, setMasterData] = useState(null);
  const [staffs, setStaffs] = useState([]);
  const [fields, handleFieldChange] = useFormFields({
    q: ""
  });

  const [formValidated, setFormValidated] = useState(false);

  
  useEffect(() => {
    if (history.action === 'PUSH' || history.action === 'REPLACE') {
      
    }

    if (history.action === 'POP') {
      if (localStorage.getItem("BUMA_LAST_KEY")===history.location.key) {
        
        return;
      }

      let ls = localStorage.getItem("BUMA_" + history.location.key);
      if (ls) {
        let prevData = JSON.parse(ls);
        setMasterData(prevData.masterData);
        setStaffs(prevData.staffs);
        handleFieldChange({target: {
          id: "UPDATE_ALL",
          value: prevData.fields
        }});
      }
    }

    return () => {};
  }, [])

  useEffect(() => {
    localStorage.setItem("BUMA_" + location.key, JSON.stringify({
      masterData: masterData,
      staffs: staffs,
      fields: fields
    }));

    return () => {
    };
  }, [masterData, staffs, fields])


  async function submit(e) {
    e.preventDefault()
    e.stopPropagation()

    const form = e.currentTarget
    setFormValidated(true);
    if (form.checkValidity() === false) {
      return;
    }

    setFormValidated(false);
    setLoading(true);

    try {
      let res = await Auth.currentSession();
      let idToken = res.getIdToken();
      let jwt = idToken.getJwtToken();

      if (!masterData) {
        let masterParams = {
          method: 'get',
          url: CONSTANTS.API_PATH + '/master_data',
          headers: { 
            Authorization: "Bearer " + jwt
          }
        };
        const masterResponse = await axios(masterParams);
        setMasterData(masterResponse.data);
      }
  
      let params = {
        method: 'get',
        url: CONSTANTS.API_PATH + '/search_staffs',
        headers: { 
          Authorization: "Bearer " + jwt
        },
        params: {
          q: fields.q
        }
      };
      const response = await axios(params);
    
      // console.log(JSON.stringify(response.data));
      setStaffs(response.data.results);
    } catch (error) {
      showNotification(0, error.message);
    }
    setLoading(false);
  }


  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Search Staffs</h1>
      </div>

      <div className="card shadow mb-4">
        <div className="card-body">

          <CForm
            className="needs-validation row px-5 gx-2"
            noValidate
            validated={formValidated}
            onSubmit={submit}
          >
            <div className="col">
            <CFormFloating>
              <CFormInput
                className="mb-4"
                type="text"
                id="q"
                placeholder="e.g. joko"
                value={fields.q}
                onChange={handleFieldChange}
                minLength={3}
                disabled={isLoading}
                autoFocus
                required />
              <CFormLabel htmlFor="confirmPassword">Name or NIK (min 3 characters)</CFormLabel>
            </CFormFloating>
            </div>
            <div className="col-auto">
            <LoaderButton
              style={{height: 56, minWidth: 56}}
              type="submit"
              bssize="large"
              isLoading={isLoading}
            >
              <FontAwesomeIcon icon={faSearch} />
            </LoaderButton>
            </div>
          </CForm>

          {isLoading ?
            <>
              <div style={{height: 500}}>
                <FontAwesomeIcon className="text-primary initialSpinner" icon={faSpinner} size="3x" spin/>
              </div>
            </>
          :
            staffs.length>0 &&
              <div className="table-responsive">
                <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>NIK</th>
                      <th>Job Title</th>
                      <th>Site</th>
                      <th>Email</th>
                      <th className="fit"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {staffs && staffs.map((item, index) => (
                      <tr key={index} className={("is_disabled" in item) && item.is_disabled && "text-danger"}>
                        <td>{item.name}{("is_disabled" in item) && item.is_disabled && " (Disabled)"}</td>
                        <td>{item.preferred_username}</td>
                        <td>{masterData.job_titles[item["custom:job_title"]]}</td>
                        <td>{masterData.sites[item["custom:site"]]}</td>
                        <td>{item.email}</td>
                        <td className="fit">
                          <Link to={`/staffs/${item.id}`} className="d-none d-sm-inline-block btn btn-sm btn-secondary shadow-sm">
                            <i className="fas fa-eye fa-sm text-white-50"></i> View
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

          }

        </div>
      </div>

    </div>

  )
}

export default Staffs;
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { CForm, CFormFloating, CFormInput, CFormLabel } from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useFormFields } from "../libs/hooksLib";
import { useAppContext } from "../libs/contextLib";
import LoaderButton from "../components/LoaderButton";
import * as CONSTANTS from "../constants";

const Admins = (props) => {
  const { showNotification } = useAppContext();
  const [formValidated, setFormValidated] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: ""
  });

  const history = useHistory();
  const location = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [isLoadingMore, setLoadingMore] = useState(false);
  const [isAddingNewAdmin, setAddingNewAdmin] = useState(false);
  const [isLoadingNewAdmin, setLoadingNewAdmin] = useState(false);

  const [masterData, setMasterData] = useState(null);
  const [admins, setAdmins] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);

  const [isDeleting, setDeleting] = useState(false);
  const [isRemovalModalShowing, setRemovalModalShowing] = useState(false);
  const [focusedAdmin, setFocusedAdmin] = useState(null);

  useEffect(() => {
    if (history.action === 'PUSH' || history.action === 'REPLACE') {
      startup();
    }

    if (history.action === 'POP') {
      // console.log(localStorage.getItem("BUMA_LAST_KEY") + " : " + history.location.key)
      if (localStorage.getItem("BUMA_LAST_KEY") === history.location.key) {
        // console.log("should reset");
        startup();
        return;
      }

      // console.log("old values");

      let ls = localStorage.getItem("BUMA_" + history.location.key);
      // console.log(ls);
      if (ls) {
        let prevData = JSON.parse(ls);
        setMasterData(prevData.masterData);
        setAdmins(prevData.admins);
        setLastEvaluatedKey(prevData.lastEvaluatedKey);
      }
    }

    return () => { };
  }, [])

  useEffect(() => {
    localStorage.setItem("BUMA_" + location.key, JSON.stringify({
      masterData: masterData,
      admins: admins,
      lastEvaluatedKey: lastEvaluatedKey
    }));

    return () => {
    };
  }, [masterData, admins, lastEvaluatedKey])

  async function startup(isLoadMore = false) {
    if (isLoadMore) setLoadingMore(true);
    else setLoading(true);

    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();

    if (!masterData) {
      let masterParams = {
        method: 'get',
        url: CONSTANTS.API_PATH + '/master_data',
        headers: {
          Authorization: "Bearer " + jwt
        }
      };
      const masterResponse = await axios(masterParams);
      setMasterData(masterResponse.data);
    }

    let params = {
      method: 'get',
      url: CONSTANTS.API_PATH + '/admins',
      headers: {
        Authorization: "Bearer " + jwt
      }
    };
    if (isLoadMore && lastEvaluatedKey) params.params = {
      last_evaluated_key: JSON.stringify(lastEvaluatedKey)
    }
    const response = await axios(params);

    // console.log(JSON.stringify(response.data));

    if (isLoadMore) {
      setAdmins(prevState => [...prevState, ...response.data.results]);
    } else {
      setAdmins(response.data.results)
    }

    if (response.data.last_evaluated_key) setLastEvaluatedKey(response.data.last_evaluated_key);
    else setLastEvaluatedKey(null);

    if (isLoadMore) setLoadingMore(false);
    else setLoading(false);
  }

  async function handleAppointAdmin(e) {
    e.preventDefault()
    e.stopPropagation()

    const form = e.currentTarget
    setFormValidated(true);
    if (form.checkValidity() === false) {
      return;
    }

    setLoadingNewAdmin(true);

    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();

    try {
      const updateResponse = await axios({
        method: 'post',
        url: CONSTANTS.API_PATH + '/appoint_admin',
        headers: {
          Authorization: "Bearer " + jwt
        },
        data: {
          email: fields.email
        }
      });
      showNotification(1, `${fields.email} has been appointed admin!`);
      setAddingNewAdmin(false);
      handleFieldChange({target: {
        id: "UPDATE_ALL",
        value: {
          email: ""
        }
      }});
      startup();
    } catch(e) {
      showNotification(0, e.response.data.message);
    }

    setLoadingNewAdmin(false);
  }

  function removeAdmin(item) {
    setFocusedAdmin(item);
    setRemovalModalShowing(true);
  }

  async function confirmRemoval() {
    setDeleting(true);

    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();

    try {
      await axios({
        method: 'post',
        url: CONSTANTS.API_PATH + '/remove_admin',
        headers: {
          Authorization: "Bearer " + jwt
        },
        data: {
          sub: focusedAdmin.sub
        }
      });
      showNotification(1, `${focusedAdmin.name} has been removed from admin list!`);
      
      let newAdmins = admins.filter(function( obj ) {
        return obj.sub !== focusedAdmin.sub;
      });

      setAdmins(newAdmins);
      setFocusedAdmin(null);
      setRemovalModalShowing(false);
    } catch(e) {
      showNotification(0, e.response.data.message);
    }

    setDeleting(false);
  }


  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Admins</h1>
        <Button onClick={() => setAddingNewAdmin(true)} className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-plus fa-sm text-white-50"></i> New Admin</Button>
      </div>

      <div className="card shadow mb-4">
        <div className="card-body">
          {isLoading ?
            <>
              <div style={{ height: 500 }}>
                <FontAwesomeIcon className="text-primary initialSpinner" icon={faSpinner} size="3x" spin />
              </div>
            </>
            :
            <div className="table-responsive">
              <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>NIK</th>
                    <th>Job Title</th>
                    <th>Site</th>
                    <th>Email</th>
                    <th className="fit"></th>
                  </tr>
                </thead>
                <tbody>
                  {admins && admins.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.preferred_username}</td>
                      <td>{masterData.job_titles[item["custom:job_title"]]}</td>
                      <td>{masterData.sites[item["custom:site"]]}</td>
                      <td>{item.email}</td>
                      <td className="fit">
                        <Button onClick={() => removeAdmin(item)} className="d-none d-sm-inline-block btn btn-sm btn-secondary shadow-sm">
                          <i className="fas fa-user-times fa-sm text-white-50"></i> Remove
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          }

          {lastEvaluatedKey &&
            <div className="d-grid">
              <LoaderButton
                onClick={() => startup(true)}
                bssize="large"
                isLoading={isLoadingMore}
              >
                Load More
              </LoaderButton>
            </div>
          }

        </div>
      </div>


      <Modal
        show={isAddingNewAdmin}
        onHide={() => {if (!isLoadingNewAdmin) setAddingNewAdmin(false)}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton={!isLoadingNewAdmin}>
          <Modal.Title>Appoint New Admin</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Please enter the email of the staff who will be appointed as admin. The staff must be registered in the portal before you can appoint him/her.</p>

          <CForm
            className="needs-validation d-grid"
            onSubmit={handleAppointAdmin}
          >
            <CFormFloating className="mb-2">
              <CFormInput
                type="email"
                id="email"
                value={fields.email}
                onChange={handleFieldChange}
                disabled={isLoadingNewAdmin}
                placeholder="e.g. mail@company.com"
                pattern="[a-z0-9._%+-]+@([a-z0-9.-]+\.[a-z]{2,})$"
                autoFocus
                required />
              <CFormLabel htmlFor="confirmPassword">Staff Email</CFormLabel>
            </CFormFloating>
            <LoaderButton
              type="submit"
              isLoading={isLoadingNewAdmin}
            >
              Appoint Admin
            </LoaderButton>
          </CForm>
        </Modal.Body>

        {/* <Modal.Footer>
          <Button variant="secondary" onClick={() => setAddingNewAdmin(false)}>Cancel</Button>
          <LoaderButton
            onClick={handleAppointAdmin}
            isLoading={isLoadingNewAdmin}
            >
            Appoint Admin
          </LoaderButton>
        </Modal.Footer> */}
      </Modal>

      <Modal
          show={isRemovalModalShowing}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          >
          <Modal.Header>
            <Modal.Title>Confirm removal</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {focusedAdmin &&
            <>
              <p>Are you sure you want to remove the following person from admin list?</p>
              <p className="text-danger font-weight-bold">Name: {focusedAdmin.name}<br/>
              NIK: {focusedAdmin.preferred_username}<br/>
              Email: {focusedAdmin.email}</p>
              <p>This person will not be able to see other staffs training results after removal.</p>
            </>
            }
          </Modal.Body>

          <Modal.Footer>
            <Button disabled={isDeleting} variant="secondary" onClick={() => setRemovalModalShowing(false)}>Cancel</Button>
            <LoaderButton isLoading={isDeleting} variant="danger" onClick={() => confirmRemoval()}>Remove</LoaderButton>
          </Modal.Footer>
        </Modal>

    </div>

  )
}

export default Admins;
import React from 'react'
import { Link, useLocation } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";

const SidebarAdmin = (props) => {
    const { isSidebarExpanded, sidebarToggle } = useAppContext();

    const location = useLocation();

    return (
        <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${!isSidebarExpanded && "toggled"}`} id="accordionSidebar">

            <span className="sidebar-brand d-flex align-items-center justify-content-center">
                <div className="sidebar-brand-icon rotate-n-15">
                    <i className="fas fa-vr-cardboard"></i>
                </div>
                <div className="sidebar-brand-text mx-2">VR Training Portal</div>
            </span>

            <hr className="sidebar-divider my-0" />

            <li className={`${location.pathname.startsWith("/dashboard") ? "active" : ""} nav-item`}>
                <Link className="nav-link" to="/dashboard">
                    <i className="fas fa-fw fa-tachometer-alt"></i>
                    <span>Dashboard</span>
                </Link>
            </li>

            <hr className="sidebar-divider" />

            <li className={`${location.pathname.startsWith("/invitation-code") ? "active" : ""} nav-item`}>
                <Link className="nav-link" to="/invitation-code">
                    <i className="fas fa-fw fa-key"></i>
                    <span>Invitation Code</span>
                </Link>
            </li>

            <li className={`${location.pathname.startsWith("/batch-registration") ? "active" : ""} nav-item`}>
                <Link className="nav-link" to="/batch-registration">
                    <i className="fas fa-fw fa-file-excel"></i>
                    <span>Batch Registration</span>
                </Link>
            </li>

            <li className={`${location.pathname.startsWith("/admins") ? "active" : ""} nav-item`}>
                <Link className="nav-link" to="/admins">
                    <i className="fas fa-fw fa-user-secret"></i>
                    <span>Admins</span>
                </Link>
            </li>

            <li className={`${location.pathname.startsWith("/staffs") ? "active" : ""} nav-item`}>
                <Link className="nav-link" to="/staffs">
                    <i className="fas fa-fw fa-users"></i>
                    <span>Staffs</span>
                </Link>
            </li>

            <li className={`${location.pathname.startsWith("/staff-training-results") ? "active" : ""} nav-item`}>
                <Link className="nav-link" to="/staff-training-results">
                    <i className="fas fa-fw fa-chart-bar"></i>
                    <span>Staff Training Results</span>
                </Link>
            </li>

            <li className={`${location.pathname.startsWith("/job-titles") ? "active" : ""} nav-item`}>
                <Link className="nav-link" to="/job-titles">
                    <i className="fas fa-fw fa-id-badge"></i>
                    <span>Job Titles</span>
                </Link>
            </li>

            <li className={`${location.pathname.startsWith("/sites") ? "active" : ""} nav-item`}>
                <Link className="nav-link" to="/sites">
                    <i className="fas fa-fw fa-building"></i>
                    <span>Sites</span>
                </Link>
            </li>

            <hr className="sidebar-divider" />

            <li className={`${location.pathname.startsWith("/training-results") ? "active" : ""} nav-item`}>
                <Link className="nav-link" to="/training-results">
                    <i className="fas fa-fw fa-chart-bar"></i>
                    <span>My Training Results</span>
                </Link>
            </li>

            <li className={`${location.pathname.startsWith("/launch-training") ? "active" : ""} nav-item`}>
                <Link className="nav-link" to="/launch-training">
                    <i className="fas fa-fw fa-vr-cardboard"></i>
                    <span>Launch Training</span>
                </Link>
            </li>

            <div className="text-center d-none d-md-inline">
                <button onClick={sidebarToggle} className="rounded-circle border-0" id="sidebarToggle"></button>
            </div>

        </ul>
    )
}

export default SidebarAdmin;
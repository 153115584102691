import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CForm, CFormFloating, CFormLabel, CFormSelect, CFormInput } from '@coreui/react';
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import * as CONSTANTS from "../constants";

const EditProfile = (props) => {
  const { userProfile, showNotification } = useAppContext();
  const { handleUserDidUpdateProfile } = props;

  const [fields, handleFieldChange] = useFormFields({
    name: userProfile["name"],
    site: userProfile["custom:site"],
    jobTitle: userProfile["custom:job_title"]
  });

  useEffect(() => {
    return () => { };
  }, []);

  const [isLoading, setLoading] = useState(false);
  const [isUpdating, setUpdating] = useState(false);
  const [isValidated, setValidated] = useState(false);

  const [masterData, setMasterData] = useState(null);

  useEffect(() => {
    startup();
    return () => { };
  }, []);

  async function startup() {
    setLoading(true);

    const response = await axios({
      method: 'get',
      url: CONSTANTS.API_PATH + '/master_data',
      params: {
        in_array: true
      }
    });

    // console.log(JSON.stringify(response.data));
    setMasterData(response.data);
    setLoading(false);
  }

  async function handleSubmit(e) {
    e.preventDefault()
    e.stopPropagation()

    const form = e.currentTarget
    setValidated(true);
    if (form.checkValidity() === false) {
      return;
    }

    setUpdating(true);

    let isNameChanged = userProfile.name!=fields.name;

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        name: fields.name,
        'custom:site': fields.site,
        'custom:job_title': fields.jobTitle
      });

      const updatedUser = await Auth.currentUserInfo();
      handleUserDidUpdateProfile(updatedUser);
      setValidated(false);

      let res = await Auth.currentSession();
      let idToken = res.getIdToken();
      let jwt = idToken.getJwtToken();
      await axios({
        method: 'post',
        url: CONSTANTS.API_PATH + '/edit_profile',
        headers: {
          Authorization: "Bearer " + jwt
        },
        data: {
          name: fields.name,
          isNameChanged: isNameChanged,
          site: fields.site.toString(),
          job_title: fields.jobTitle.toString()
        }
      });

      showNotification(1, "Profile updated!");
    } catch (e) {
      showNotification(0, e.message);
    }
    setUpdating(false);
  }

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Edit Profile</h1>
      </div>


      <div className="card shadow mb-4">
        <div className="card-body">
          {isLoading ?
            <div style={{ height: 200 }}>
              <FontAwesomeIcon className="text-primary initialSpinner" icon={faSpinner} size="3x" spin />
            </div>
            :
            masterData &&
            <div className="row">
              <div className="col-md-6 col-12">
                <CForm
                  className="loginForm needs-validation d-grid"
                  noValidate
                  validated={isValidated}
                  onSubmit={handleSubmit}
                >
                  <CFormFloating>
                    <CFormInput
                      className="mb-2"
                      type="name"
                      id="name"
                      placeholder="e.g. Joko Taro"
                      value={fields.name}
                      onChange={handleFieldChange}
                      disabled={isLoading}
                      required />
                    <CFormLabel htmlFor="name">Full Name</CFormLabel>
                  </CFormFloating>
                  <CFormFloating>
                    <CFormSelect
                      id="jobTitle"
                      className="mb-2"
                      aria-label="Job Title"
                      onChange={handleFieldChange}
                      defaultValue={fields.jobTitle}
                      disabled={isUpdating}
                      required >
                      <option value="">-</option>
                      {masterData &&
                        masterData.job_titles.map(item => (
                          <option value={item.id} key={item.id}>{item.name}</option>
                        ))
                      }
                    </CFormSelect>
                    <CFormLabel htmlFor="jobTitle">Job Title</CFormLabel>
                  </CFormFloating>
                  <CFormFloating>
                    <CFormSelect
                      id="site"
                      className="mb-4"
                      aria-label="Site"
                      onChange={handleFieldChange}
                      defaultValue={fields.site}
                      disabled={isUpdating}
                      required >
                      <option value="">-</option>
                      {masterData &&
                        masterData.sites.map(item => (
                          <option value={item.id} key={item.id}>{item.name}</option>
                        ))
                      }
                    </CFormSelect>
                    <CFormLabel htmlFor="site">Site</CFormLabel>
                  </CFormFloating>

                  <LoaderButton
                    type="submit"
                    bssize="large"
                    isLoading={isUpdating}
                  >
                    Update Profile
                  </LoaderButton>
                </CForm>
              </div>
            </div>
          }
        </div>
      </div>


    </div>

  )
}

export default EditProfile;
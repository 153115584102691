import React, { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import XLSX from 'xlsx';
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import * as CONSTANTS from "../constants";

export default function BatchRegistration() {
  const [tableData, setTableData] = useState(null);
  const [jobTitles, setJobTitles] = useState(null);
  const [sites, setSites] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isDataValid, setDataValid] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [resultData, setResultData] = useState(null);

  async function handleFileUpload(e) {
    if (!e.target.files) return;

    setLoading(true);

    const file = e.target.files[0];

    const buff = await file.arrayBuffer();
    const workbook = XLSX.read(buff);

    const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const data = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 });

    e.target.value = null;


    const response = await axios({
      method: 'get',
      url: CONSTANTS.API_PATH + '/master_data',
      params: {
        in_array: true
      }
    });

    // console.log(JSON.stringify(response.data));
    // setMasterData(response.data);
    let jt = [];
    response.data.job_titles.forEach(element => {
      jt.push(element.id);
    });
    setJobTitles(jt);

    let s = [];
    response.data.sites.forEach(element => {
      s.push(element.id);
    });
    setSites(s);


    let finalData = [];

    setDataValid(true);

    for (var i = 1; i < data.length; i++) {
      // console.log(data[i][0]);
      if (data[i][0] === "" || data[i][0] === undefined) continue;

      finalData.push({
        name: data[i][0],
        email: data[i][1].toLowerCase(),
        nik: data[i][2],
        job_title: data[i][3],
        site: data[i][4],
        password: data[i][5]
      });

      if (!data[i][1].toLowerCase().match(/^[a-z0-9._%+-]+@([a-z0-9.-]+\.[a-z]{2,})$/)) setDataValid(false);
      if (data[i][2].toString().length < 8) setDataValid(false);
      if (!jt.includes(data[i][3].toString())) setDataValid(false);
      if (!s.includes(data[i][4].toString())) setDataValid(false);
      if (data[i][5].length < 8) setDataValid(false);

      if (finalData.length >= 50) break;
    }

    setTableData(finalData);
    setLoading(false);
  }

  async function upload() {
    setUploading(true);

    try {
      let res = await Auth.currentSession();
      let idToken = res.getIdToken();
      let jwt = idToken.getJwtToken();

      let response = await axios({
        method: 'post',
        url: CONSTANTS.API_PATH + '/upload_batch_registration',
        headers: {
          Authorization: "Bearer " + jwt
        },
        data: {
          staffs: tableData
        }
      });

      setResultData(response.data.results);
    } catch (e) {

    }

    setUploading(false);
  }

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Batch Registration</h1>
      </div>

      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              {isLoading ?
                <div style={{ height: 200 }}>
                  <FontAwesomeIcon className="text-primary initialSpinner" icon={faSpinner} size="3x" spin />
                </div>
                :
                !tableData ?
                  <div className="text-center mb-4 pt-3">
                    {/* <h4>Upload registration excel file</h4> */}
                    <input
                      id="fileUpload"
                      type="file"
                      accept=".xlsx,.xls"
                      onChange={handleFileUpload}
                      className="d-none"
                    />
                    <label htmlFor="fileUpload" className={`btn btn-primary ${isUploading ? "disabled" : ""}`}>Select excel file to upload</label> or <a href="https://buma-api-prod.s3.ap-southeast-1.amazonaws.com/buma_batch_registration_template.xlsx" target="_blank">download the excel template</a>
                    <p className="text-secondary">*Max 50 staffs in 1 excel file</p>
                  </div>
                  :
                  <>
                    <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                      {/* {JSON.stringify(tableData)} */}
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>NIK</th>
                          <th>Job Title ID</th>
                          <th>Site ID</th>
                          <th className="fit">Password (minimum 8 characters)</th>
                          {resultData &&
                            <th>Result</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td className={!item.email.toLowerCase().match(/^[a-z0-9._%+-]+@([a-z0-9.-]+\.[a-z]{2,})$/) ? "text-danger" : ""}>{item.email}</td>
                            <td className={item.nik.toString().length < 8 ? "text-danger" : ""}>{item.nik}</td>
                            <td className={!jobTitles.includes(item.job_title.toString()) ? "text-danger" : ""}>{item.job_title}</td>
                            <td className={!sites.includes(item.site.toString()) ? "text-danger" : ""}>{item.site}</td>
                            <td className={item.password.length < 8 ? "text-danger" : ""}>{item.password}</td>
                            {resultData &&
                              <td>
                                {resultData[index] ?
                                  <span className="text-danger">{resultData[index].replaceAll("PreSignUp failed with error ", "")}</span>
                                  :
                                  <FontAwesomeIcon className="text-success" icon={faCheck} />
                                }
                              </td>
                            }
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {!resultData &&
                      <>
                        <p className="text-center mt-5 mb-4">Please make sure the table data above is correct before proceeding, especially <u>NIK</u> and <u>email</u></p>
                        <div className="d-grid">
                          <LoaderButton
                            className="btn-lg"
                            isLoading={isUploading}
                            disabled={!isDataValid}
                            onClick={() => upload()}
                          >
                            Register Staffs in Table
                          </LoaderButton>
                        </div>
                      </>
                    }
                  </>
              }
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
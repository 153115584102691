import React from "react";
import "./NotFound.css";

export default function NotFound() {
  return (
    <div className="h-100">
      <div className="NotFound">
        <h1>404</h1>
        <h3>Sorry, page not found!</h3>
        <a href="http://localhost:3000/confirm?code=605251&username=d51fb7b7-f336-476c-9d9d-2330a631ad53&clientId=1k99vprpcjpf76mp9r63d6976h&region=ap-southeast-1&email=reyn+1@virtu.co.id">Confirm</a>
      </div>
    </div>
  );
}
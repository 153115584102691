import React, { useEffect, useState } from "react";
import { Image, Button } from "react-bootstrap";
import { useParams, useHistory, Link } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import * as CONSTANTS from "../constants";

const LaunchTraining = (props) => {
  const { userProfile } = useAppContext();
  const history = useHistory();

  let { target, mode, field } = useParams();
  
  // const [step, setStep] = useState(0);
  // const [target, setTarget] = useState(-1);
  // const [mode, setMode] = useState(-1);
  // const [field, setField] = useState(-1);
  // const [module, setModule] = useState(-1);

  const [urlScheme, setURLScheme] = useState("");

  useEffect(() => {
    if (target && !CONSTANTS.TRAINING_TARGETS.find(item => { return item.id===target })) history.push("/launch-training");
    if (mode && !CONSTANTS.TRAINING_MODES.find(item => { return item.id===mode })) history.push("/launch-training");
    if (field && !CONSTANTS.TRAINING_FIELDS.find(item => { return item.id===field })) history.push("/launch-training");

    if (target && mode && field) {
      var prefix = "";
      if (field==="1") prefix = "virtubuma";
      else if (field==="2") prefix = "virtubumaoperator";
      else if (field==="3") prefix = "virtubumamechanic";
      else if (field==="4") prefix = "virtubumasafety";

      let apiURL = "https%3A%2F%2Fbuma.virtu.co.id";

      let url = `${prefix}://vr?url=${apiURL}&target=${target}&mode=${mode}&name=${userProfile.name}&nik=${userProfile.preferred_username}&sub=${userProfile.sub}&module=`;
      // console.log(url);
      setURLScheme(url);
    }
    return () => {};
  }, [target, mode, field]);

  function selectTarget(t) {
    // setTarget(t);
    // setStep(1);
    history.push(`/launch-training/${t}`);
  }

  function selectMode(m) {
    // setMode(m);
    // setStep(2);
    history.push(`/launch-training/${target}/${m}`);
  }

  function selectField(f) {
    // setField(f);
    // setStep(3);
    history.push(`/launch-training/${target}/${mode}/${f}`);
  }

  function selectModule(m) {
    // setModule(m);

    var prefix = "";
    if (field==="1") prefix = "virtubuma";
    else if (field==="2") prefix = "virtubumaoperator";
    else if (field==="3") prefix = "virtubumamechanic";
    else if (field==="4") prefix = "virtubumasafety";

    let apiURL = "https%3A%2F%2Fbuma.virtu.co.id";

    let url = `${prefix}://vr?url=${apiURL}&target=${target}&mode=${mode}&name=${userProfile.name}&nik=${userProfile["preferred_username"]}&sub=${userProfile.sub}&module=`
    setURLScheme(url);
    // window.open(url, '_blank');

    // setTimeout(function () { window.location = "https://apps.apple.com/sg/app/microsoft-teams/id1113153706"; }, 25);
    // window.location = prefix + "://";

    // var now = new Date().valueOf();
    // setTimeout(function () {
    //   if (new Date().valueOf() - now > 100) return;
    //   window.location = "https://apps.apple.com/sg/app/microsoft-teams/id1113153706";
    // }, 25);
    window.location = prefix + "://";

    // if (field===1) {
    //   if (m===1) {
    //     window.open(url, '_blank');
    //   }
    // }
  }

  function renderSelectTrainingTarget() {
    return (
      <div className="card shadow mb-4">
        <div className="card-body">
          <h3 className="text-center mb-4">Select Training Target</h3>
          <div className="row">

            <div className="col-12">
              <div className="card mb-4">
                {/* <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary text-center">Training Target</h6>
                </div> */}
                <div className="card-body">
                  <div className="text-center d-grid">
                    <p><Image src="/img/training_target.png" fluid /></p>
                    <div className="row gx-4">
                      {CONSTANTS.TRAINING_TARGETS.map((item, index) => (
                        <div className="col-lg-6 col-12 mb-2" key={item.id}>
                          <Button onClick={() => selectTarget(item.id)} className="btn btn-primary btn-user btn-block mb-2">
                            {item.name}
                          </Button>
                        </div>
                      ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }

  function renderSelectMode() {
    return (
      <div className="card shadow mb-4">
        <div className="card-body">
          <h3 className="text-center mb-4">Select Training Mode</h3>
          <div className="row">
            {CONSTANTS.TRAINING_MODES.map((item, index) => (
              <div className="col-lg-6 col-12" key={item.id}>
                <div className="card mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary text-center">{item.name}</h6>
                  </div>
                  <div className="card-body">
                    <div className="text-center d-grid">
                      <p><Image src={item.img} fluid /></p>
                      <Button onClick={() => selectMode(item.id)} className="btn btn-primary btn-user btn-block">
                        Select
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))
            }
          </div>
        </div>
      </div>
    );
  }

  function renderSelectField() {
    return (
      <div className="card shadow mb-4">
        <div className="card-body">
          <h3 className="text-center mb-4">Select Training Field</h3>
          <div className="row">

            {CONSTANTS.TRAINING_FIELDS.map((item, index) => (
              <div className="col-lg-4 col-md-6 col-12" key={item.id}>
                <div className="card mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary text-center">{item.name}</h6>
                  </div>
                  <div className="card-body">
                    <div className="text-center d-grid">
                      <p><Image src={item.img} fluid /></p>
                      <Button onClick={() => selectField(item.id)} className="btn btn-primary btn-user btn-block">
                        Select
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))
            }
          </div>
        </div>
      </div>
    );
  }

  function renderSelectModule() {
    return (
      <div className="card shadow mb-4">
        <div className="card-body">
          <h3 className="text-center mb-4">Select Training Module</h3>
          <div className="row">

            {CONSTANTS.TRAINING_FIELDS.find(item => { return item.id===field })?.modules?.map((item, index) => (
              <div className="col-lg-4 col-md-6 col-12" key={item.id}>
                <div className="card mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary text-center">{item.name}</h6>
                  </div>
                  <div className="card-body">
                    <div className="text-center d-grid">
                      <p><Image src={item.img} fluid /></p>
                      {
                        item.modules ?
                          <>
                            {item.modules?.map((sub, index) => (
                              // <Button onClick={() => selectModule(sub.id)} className="btn btn-primary btn-user btn-block mb-2" title={sub.id} key={sub.id}>
                              //   {sub.name}
                              // </Button>
                              <Button href={urlScheme + sub.id} className="btn btn-primary btn-user btn-block mb-2" title={sub.id} key={sub.id}>
                                {sub.name}
                              </Button>
                            ))
                            }
                          </>
                        :
                          // <Button onClick={() => selectModule(item.id)} className="btn btn-primary btn-user btn-block" title={item.id}>
                          //   Select
                          // </Button>
                          <Button href={urlScheme + item.id} className="btn btn-primary btn-user btn-block" title={item.id}>
                            Select
                          </Button>
                      }
                      
                    </div>
                  </div>
                </div>
              </div>
            ))
            }

          </div>
        </div>
      </div>
    )
  }

  function getTargetName() {
    if (target) {
      return (
        <>
          <b>Target: {CONSTANTS.TRAINING_TARGETS.find(item => { return item.id===target })?.name}</b>
          <br/>
        </>
      );
      
    } else {
      return "";
    }
  }

  function getModeName() {
    if (mode) {
      return (
        <>
          <b>Mode: {CONSTANTS.TRAINING_MODES.find(item => { return item.id===mode })?.name}</b>
          <br/>
        </>
      );
      
    } else {
      return "";
    }
  }

  function getFieldName() {
    if (field) {
      return (
        <>
          <b>Field: {CONSTANTS.TRAINING_FIELDS.find(item => { return item.id===field })?.name}</b>
          <br/>
        </>
      );
      
    } else {
      return "";
    }
  }

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Launch Training</h1>
      </div>

      {(target || mode || field) &&
      <div className="row">
        <div className="col-auto">
          <div className="card shadow mb-4">
            <div className="card-body">
                <div className="well">
                    <p className="mb-0">
                      {getTargetName()}
                      {getModeName()}
                      {getFieldName()}
                    </p>
                </div>
            </div>
          </div>
        </div>
      </div>
      }

      
      {!target && renderSelectTrainingTarget()}
      {target && !mode && renderSelectMode()}
      {target && mode && !field && renderSelectField()}
      {target && mode && field && renderSelectModule()}

    </div>

  )
}

export default LaunchTraining;
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./GenerateReport.css";
import { useAppContext } from "../libs/contextLib";
import LoaderButton from "../components/LoaderButton";
import * as CONSTANTS from "../constants";
import { CForm, CFormCheck } from '@coreui/react';

const GenerateReport = (props) => {
  const { showNotification } = useAppContext();

  const [isLoading, setLoading] = useState(false);
  const [isGenerating, setGenerating] = useState(false);
  const [jobTitles, setJobTitles] = useState([]);
  const [sites, setSites] = useState([]);
  const [generateProgress, setGenerateProgress] = useState(0);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [modules, setModules] = useState([]);

  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedModes, setSelectedModes] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedJobTitles, setSelectedJobTitles] = useState([]);

  const [excelLink, setExcelLink] = useState(null);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    startup();

    return () => {};
  }, []);
  
  async function startup() {
    setLoading(true);

    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();

    const jobTitleResponse = await axios({
      method: 'get',
      url: CONSTANTS.API_PATH + '/job_titles',
      headers: { 
        Authorization: "Bearer " + jwt
      }
    });
    setJobTitles(jobTitleResponse.data.job_titles);

    const sitesResponse = await axios({
      method: 'get',
      url: CONSTANTS.API_PATH + '/sites',
      headers: { 
        Authorization: "Bearer " + jwt
      }
    });
    setSites(sitesResponse.data.sites);

    let keys = Object.keys(CONSTANTS.TRAINING_MODULES).sort((a,b) => { return parseInt(a) > parseInt(b) ? 1 : parseInt(b) > parseInt(a) ? -1 : 0 });

    let arr = [];
    let sModules = [];
    keys.forEach((key, index) => {
      let mod = CONSTANTS.TRAINING_MODULES[key];
      mod.id = key;
      arr.push(mod);
      sModules.push(key);
    });
    setModules(arr);
    setSelectedModules(sModules);

    let sModes = [];
    CONSTANTS.TRAINING_MODES.forEach((item, index) => {
      sModes.push(item.id);
    });
    setSelectedModes(sModes);

    let sSites = [];
    sitesResponse.data.sites.forEach((item, index) => {
      sSites.push(item.id);
    });
    setSelectedSites(sSites);

    let sJobTitles = [];
    jobTitleResponse.data.job_titles.forEach((item, index) => {
      sJobTitles.push(item.id);
    });
    setSelectedJobTitles(sJobTitles);

    setLoading(false);
  }

  // function onDateRangeChange(dates) {
  //   const [start, end] = dates;
  //   setStartDate(start);
  //   setEndDate(end);
  // };

  function onUpdateModuleCheck(e) {
    if (e.target.checked) {
      setSelectedModules(selectedModules.push(e.target.value));
    } else {
      setSelectedModules(selectedModules.filter(function(value, index, arr){ 
        return value !== e.target.value;
    }));
    }
  }

  function onUpdateModeCheck(e) {
    if (e.target.checked) {
      setSelectedModes(selectedModes.push(e.target.value));
    } else {
      setSelectedModes(selectedModes.filter(value => value !== e.target.value));
    }
  }

  function onUpdateSiteCheck(e) {
    if (e.target.checked) {
      setSelectedSites(selectedSites.push(e.target.value));
    } else {
      setSelectedSites(selectedSites.filter(value => value !== e.target.value));
    }
  }

  function onUpdateJobTitleCheck(e) {
    if (e.target.checked) {
      setSelectedJobTitles(selectedJobTitles.push(e.target.value));
    } else {
      setSelectedJobTitles(selectedJobTitles.filter(value => value !== e.target.value));
    }
  }

  async function onSubmitForm(e) {
    e.preventDefault();
    e.stopPropagation();

    setExcelLink(null);
    setGenerating(true);
    setGenerateProgress(0);

    var selectedAllModules = true;
    if (selectedModules.length===0) selectedAllModules = true;
    else {
      let keys = Object.keys(CONSTANTS.TRAINING_MODULES).sort((a,b) => { return parseInt(a) > parseInt(b) ? 1 : parseInt(b) > parseInt(a) ? -1 : 0 });
      keys.forEach((key, index) => {
        if (!selectedModules.includes(key)) {
          selectedAllModules = false;
        }
      });
    }
    // console.log("Modules: " + selectedAllModules);

    var selectedAllModes = true;
    if (selectedModes.length===0) selectedAllModes = true;
    else CONSTANTS.TRAINING_MODES.forEach((item, index) => {
      if (!selectedModes.includes(item.id)) {
        selectedAllModes = false;
      }
    });
    // console.log("Modes: " + selectedAllModes);

    var selectedAllSites = true;
    if (selectedSites.length===0) selectedAllSites = true;
    else sites.forEach((item, index) => {
      if (!selectedSites.includes(item.id)) {
        selectedAllSites = false;
      }
    });
    // console.log("Sites: " + selectedAllSites);

    var selectedAllJobTitles = true;
    if (selectedJobTitles.length===0) selectedAllJobTitles = true;
    else jobTitles.forEach((item, index) => {
      if (!selectedJobTitles.includes(item.id)) {
        selectedAllJobTitles = false;
      }
    });
    // console.log("Job Titles: " + selectedAllJobTitles);

    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();

    let params = {
      start: moment(startDate).format('YYYYMMDD'),
      end: moment(endDate).format('YYYYMMDD')
    };
    if (!selectedAllModules) params.modules = selectedModules;
    if (!selectedAllModes) params.modes = selectedModes;
    if (!selectedAllSites) params.sites = selectedSites;
    if (!selectedAllJobTitles) params.job_titles = selectedJobTitles;

    var progressKey;

    try {
      const generateReportResponse = await axios({
        method: 'post',
        url: CONSTANTS.API_PATH + '/generate_report_long',
        headers: { 
          Authorization: "Bearer " + jwt
        },
        data: params
      });
      // console.log(JSON.stringify(generateReportResponse.data));

      progressKey = generateReportResponse.data.progress_key;

      // await delay(5000);
      // setExcelLink(generateReportResponse.data.url);

      // window.open(generateReportResponse.data.url, '_blank');
    } catch (e) {
      // showNotification(0, e.message);
      showNotification(0, e.response.data.message);
      setGenerating(false);
      return;
    }

    // setGenerating(false);

    var isFinished = false;

    do {
      await delay(5000);
      try {
        const progressResponse = await axios({
          method: 'get',
          url: CONSTANTS.API_PATH + '/generate_report_progress',
          headers: { 
            Authorization: "Bearer " + jwt
          },
          params: {
            progress_key: progressKey
          }
        });
        // console.log(JSON.stringify(progressResponse.data.progress));
        setGenerateProgress(progressResponse.data.progress.percentage);
        isFinished = progressResponse.data.progress.isFinished;

        if (isFinished) {
          setExcelLink(progressResponse.data.progress.url);
          window.open(progressResponse.data.progress.url, '_blank');
        }
      } catch (e) {
        console.log(e.message);
      }
    } while(!isFinished);

    setGenerating(false);
  }

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Generate Report</h1>
      </div>

      {isLoading ?
        <div className="card shadow">
          <div className="card-body">
            <div style={{height: 500}}>
              <FontAwesomeIcon className="text-primary initialSpinner" icon={faSpinner} size="3x" spin/>
            </div>
          </div>
        </div>
      :

        <CForm onSubmit={onSubmitForm}>
          <div className="row align-items-center justify-content-center mb-4">
            <div className="col-lg-6">
              <div className="card shadow">
                <div className="card-body">
                  <div className="form-group">
                    <label>Date Range</label>
                    <div className="input-group input-daterange">
                      <DatePicker
                        selectsStart
                        name="startDate"
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                        disabled={isGenerating}
                        dateFormat="dd-MM-yyyy"
                        className="text-center"
                        onChange={(date) => setStartDate(date)} />
                      <div className="input-group-addon">&nbsp;&nbsp;to&nbsp;&nbsp;</div>
                      <DatePicker
                        selectsEnd
                        name="endDate"
                        selected={endDate}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        // maxDate={new Date(startDate.getMonth() + 1)}
                        disabled={isGenerating}
                        dateFormat="dd-MM-yyyy"
                        className="text-center"
                        onChange={(date) => setEndDate(date)} />
                        {/* <DatePicker
                          selected={startDate}
                          onChange={onDateRangeChange}
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat="dd-MM-yyyy"
                          selectsRange
                          inline
                        /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row gx-3 mb-4">
            <div className="col-lg-3 mb-4">
              <div className="card shadow">
                <div className="card-body">
                  <div className="form-group">
                    <label>Module</label>
                    {modules.map((module, index) => (
                      <CFormCheck
                        key={module.id}
                        value={module.id}
                        label={module.name}
                        disabled={isGenerating}
                        checked={selectedModules.includes(module.id)}
                        onChange={onUpdateModuleCheck} />
                    ))
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 mb-4">
              <div className="card shadow">
                <div className="card-body">
                  <div className="form-group">
                    <label>Mode</label>
                    {CONSTANTS.TRAINING_MODES.map((mode, index) => (
                      <CFormCheck
                        key={mode.id}
                        value={mode.id}
                        label={mode.name}
                        disabled={isGenerating}
                        checked={selectedModes.includes(mode.id)}
                        onChange={onUpdateModeCheck} />
                    ))
                    }
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-lg-3 mb-4">
              <div className="card shadow">
                <div className="card-body">
                  <div className="form-group">
                    <label>Sites</label>
                    {sites.map((site, index) => (
                      <CFormCheck
                        key={site.id}
                        value={site.id}
                        label={site.name}
                        disabled={isGenerating}
                        checked={selectedSites.includes(site.id)}
                        onChange={onUpdateSiteCheck} />
                    ))
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 mb-4">
              <div className="card shadow">
                <div className="card-body">
                  <div className="form-group">
                    <label>Job Titles</label>
                    {jobTitles.map((jobTitle, index) => (
                      <CFormCheck
                        key={jobTitle.id}
                        value={jobTitle.id}
                        label={jobTitle.name}
                        disabled={isGenerating}
                        checked={selectedJobTitles.includes(jobTitle.id)}
                        onChange={onUpdateJobTitleCheck} />
                    ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>


          {excelLink &&
            <div className="row mb-4">
              <div className="col-12 d-grid text-center">
                Your report has been successfully generated!
                <br />
                If your browser does not automatically download the excel file,
                <br />
                <a href={excelLink} target="_blank">click here to manually download it</a>.
              </div>
            </div>
          }


          <div className="row mb-4">
            <div className="col-12 d-grid">
              <LoaderButton
                type="submit"
                bssize="large"
                isLoading={isGenerating}
              >
                Generate Spreadsheet Report{isGenerating ? " ("+Math.floor(generateProgress)+"%)" : ""}
              </LoaderButton>
            </div>
          </div>
        </CForm>

      }

    </div>

  )
}

export default GenerateReport;
import React, { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import Routes from "./Routes";
import './App.css';
import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";
import awsconfig from "./aws-exports";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { store } from 'react-notifications-component';
import { isMobile } from "react-device-detect";

Amplify.configure(awsconfig);

let App = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userProfile, userHasProfile] = useState(null);
  const [userLevel, userHasLevel] = useState(0);

  const [isSidebarExpanded, setSidebarExpanded] = useState(!isMobile);
  const [isLogoutModalShowing, setLogoutModalShowing] = useState(false);

  const history = useHistory();

  // const match = useRouteMatch("/login")


  useEffect(() => {
    // console.log("mount " + localStorage.getItem("BUMA_LAST_KEY"));
    
    const unlisten = history.listen((location, action) =>  {
      // console.log("listen " + location.key);
      localStorage.setItem("BUMA_LAST_KEY", null);
    });
    return () => {
      unlisten();
    };
  }, [history.location]);

  window.onbeforeunload = (e) => {
    // console.log("unmount " + history.location.key);
    localStorage.setItem("BUMA_LAST_KEY", history.location.key);
  };

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);

      let user = await Auth.currentUserInfo();
      userHasProfile(user.attributes);
      userHasLevel(parseInt(user.attributes["custom:level"]));

      // let res = await Auth.currentSession();
      // let idToken = res.getIdToken();
      // let jwt = idToken.getJwtToken();
      // console.log(jwt);
    }
    catch(e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }
  
    setIsAuthenticating(false);
  }

  function handleUserDidUpdateProfile(newUserProfile) {
    userHasProfile(newUserProfile.attributes);
  }

  function handleLogoutModalClose() {
    setLogoutModalShowing(false);
  }

  function sidebarToggle() {
    setSidebarExpanded(!isSidebarExpanded);
  }

  function showNotification(type, message) {
    store.addNotification({
      title: "",
      message: message,
      type: type===0 ? "danger" : "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  }

  function handleLogoutModalOpen() {
    setLogoutModalShowing(true);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);
    userHasProfile(null);
    setLogoutModalShowing(false);
    userHasLevel(0);

    history.push("/");
  }
  
  return (
    !isAuthenticating ? 
    <div id="wrapper" className="min-vh-100">
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, userProfile, userHasProfile, userLevel, userHasLevel, handleLogoutModalOpen, isSidebarExpanded, sidebarToggle, showNotification }}>
        <Routes showNotification={showNotification} handleUserDidUpdateProfile={handleUserDidUpdateProfile} />


        <Modal
          show={isLogoutModalShowing}
          onHide={handleLogoutModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          >
          <Modal.Header closeButton>
            <Modal.Title>Confirm logout</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to logout?</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleLogoutModalClose}>Cancel</Button>
            <Button variant="danger" onClick={handleLogout}>Logout</Button>
          </Modal.Footer>
        </Modal>
      </AppContext.Provider>
    </div>
    :
    <div id="wrapper" className="min-vh-100">
      <FontAwesomeIcon className="text-primary initialSpinner" icon={faSpinner} size="3x" spin/>
    </div>
  );

};

export default App;

// export const API_PATH = 'http://localhost:9000/prod';
export const API_PATH = 'https://buma-api.virtu.co.id';

export const TRAINING_TARGETS = [
  {
    id: "1",
    name: "Basic Operator"
  },
  {
    id: "2",
    name: "Basic Mechanic"
  },
  {
    id: "3",
    name: "Skill Up"
  },
  {
    id: "4",
    name: "Add Skill"
  },
  {
    id: "5",
    name: "SCD"
  },
  {
    id: "6",
    name: "Safety"
  },
  {
    id: "7",
    name: "Refreshment"
  },
  {
    id: "8",
    name: "IDP Training"
  }
];

export const TRAINING_MODES = [
  {
    id: "0",
    name: "Tutorial",
    img: "/img/icon_training.jpg"
  },
  {
    id: "1",
    name: "Assessment",
    img: "/img/icon_assessment.png"
  }
];

export const TRAINING_FIELDS = [
  {
    id: "1",
    name: "Supervisor",
    img: "/img/field_supervisory.png",
    modules: [
      {
        id: 1,
        name: "Front Loading",
        img: "/img/supervisory_loading.png"
      },
      {
        id: 2,
        name: "Hauling",
        img: "/img/supervisory_hauling.png"
      },
      {
        id: 3,
        name: "Disposal",
        img: "/img/supervisory_disposal.png"
      }
    ]
  },
  {
    id: "2",
    name: "Operator",
    img: "/img/field_operator.png",
    modules: [
      {
        id: 1,
        name: "Komatsu HD785-7",
        img: "/img/operator_hd785.png",
        modules: [
          {
            id: 1001,
            name: "Bab 1 - Pengenalan Unit & Komponen"
          },
          {
            id: 1002,
            name: "Bab 2 - Pemahaman Instrumen Panel & Kontrol"
          },
          {
            id: 1003,
            name: "Bab 3 - Pelaksanaan Perawatan Harian (P2H)"
          },
          {
            id: 1004,
            name: "Bab 4 - Metode & Teknik Operasi"
          }
        ]
      },
      {
        id: 2,
        name: "Komatsu D155A-6",
        img: "/img/operator_d155.png",
        modules: [
          {
            id: 1005,
            name: "Bab 1 - Pengenalan Unit & Komponen"
          },
          {
            id: 1006,
            name: "Bab 2 - Pemahaman Instrumen Panel & Kontrol"
          },
          {
            id: 1007,
            name: "Bab 3 - Pelaksanaan Perawatan Harian (P2H)"
          },
          {
            id: 1008,
            name: "Bab 4 - Metode & Teknik Operasi"
          }
        ]
      },
      {
        id: 3,
        name: "Komatsu PC200-8",
        img: "/img/operator_pc200.png",
        modules: [
          {
            id: 1009,
            name: "Bab 1 - Pengenalan Unit & Komponen"
          },
          {
            id: 1010,
            name: "Bab 2 - Pemahaman Instrumen Panel & Kontrol"
          },
          {
            id: 1011,
            name: "Bab 3 - Pelaksanaan Perawatan Harian (P2H)"
          },
          {
            id: 1012,
            name: "Bab 4 - Metode & Teknik Operasi"
          }
        ]
      },
      {
        id: 4,
        name: "Volvo FMX440",
        img: "/img/operator_fmx440.jpeg",
        modules: [
          {
            id: 1013,
            name: "Bab 1 - Pengenalan Unit & Komponen"
          },
          {
            id: 1014,
            name: "Bab 2 - Pemahaman Instrumen Panel & Kontrol"
          },
          {
            id: 1015,
            name: "Bab 3 - Pelaksanaan Perawatan Harian (P2H)"
          },
          {
            id: 1016,
            name: "Bab 4 - Metode & Teknik Operasi"
          }
        ]
      },
      {
        id: 5,
        name: "Mercedes Benz 4054 DT",
        img: "/img/operator_dt.jpg",
        modules: [
          {
            id: 1017,
            name: "Bab 1 - Pengenalan Unit & Komponen"
          },
          {
            id: 1018,
            name: "Bab 2 - Pemahaman Instrumen Panel & Kontrol"
          },
          {
            id: 1019,
            name: "Bab 3 - Pelaksanaan Perawatan Harian (P2H)"
          },
          {
            id: 1020,
            name: "Bab 4 - Metode & Teknik Operasi"
          }
        ]
      }
    ]
  },
  {
    id: "3",
    name: "Mechanic",
    img: "/img/field_mechanic.png",
    modules: [
      {
        id: 1,
        name: "Komatsu HD785-7",
        img: "/img/operator_hd785.png",
        modules: [
          {
            id: 2001,
            name: "Bab 1 - Akses Peralatan"
          },
          {
            id: 2002,
            name: "Bab 2 - Spesifikasi & Feature"
          },
          {
            id: 2003,
            name: "Bab 3 - Pengenalan Komponen Rute 1-3"
          },
          {
            id: 2004,
            name: "Bab 4 - Pengenalan Komponen Rute 4-6"
          },
          {
            id: 2005,
            name: "Bab 5 - Pengenalan Komponen Rute 7-9"
          },
          {
            id: 2006,
            name: "Bab 6 - Pengenalan Komponen Rute 10-12"
          }
        ]
      },
      {
        id: 2,
        name: "Komatsu PC200-8",
        img: "/img/operator_pc200.png",
        modules: [
          {
            id: 2007,
            name: "Bab 1 - Akses Peralatan"
          },
          {
            id: 2008,
            name: "Bab 2 - Spesifikasi & Feature"
          },
          {
            id: 2009,
            name: "Bab 3 - Pengenalan Komponen Rute 1-3"
          },
          {
            id: 2010,
            name: "Bab 4 - Pengenalan Komponen Rute 4-6"
          },
          {
            id: 2011,
            name: "Bab 5 - Pengenalan Komponen Rute 7-9"
          },
          {
            id: 2012,
            name: "Bab 6 - Pengenalan Komponen Rute 10-12"
          }
        ]
      }
    ]
  },
  {
    id: "4",
    name: "Safety",
    img: "/img/field_safety.jpg",
    modules: [
      {
        id: 1,
        name: "Fire Emergency",
        img: "/img/safety_fire.jpg",
        modules: [
          {
            id: 3001,
            name: "Bab 1 - APAR di Area Tambang"
          },
          {
            id: 3002,
            name: "Bab 2 - Fire Suppression di Area Tambang"
          },
          {
            id: 3003,
            name: "Bab 3 - Area Bangunan"
          },
          {
            id: 3004,
            name: "Bab 4 - Area Power Genset"
          },
          {
            id: 3005,
            name: "Bab 5 - Area Main Tank"
          }
        ]
      },
      {
        id: 2,
        name: "Medical Emergency",
        img: "/img/safety_medical.jpg",
        modules: [
          {
            id: 3006,
            name: "Bab 1 - Bantuan Hidup Dasar"
          },
          {
            id: 3007,
            name: "Bab 2 - Patah Tulang"
          },
          {
            id: 3008,
            name: "Bab 3 - Luka Bakar & Pendarahan"
          },
          {
            id: 3009,
            name: "Bab 4 - Tersedak"
          }
        ]
      },
      {
        id: 3,
        name: "Risk Identification",
        img: "/img/safety_risk.jpg",
        modules: [
          {
            id: 3010,
            name: "Bab 1 - Area Front Loading"
          },
          {
            id: 3011,
            name: "Bab 2 - Area Jalan Hauling"
          },
          {
            id: 3012,
            name: "Bab 3 - Area Disposal"
          },
          {
            id: 3013,
            name: "Bab 4 - Area Workshop"
          },
          {
            id: 3014,
            name: "Bab 5 - Area Fabrikasi"
          },
          {
            id: 3015,
            name: "Bab 6 - Area Gudang Sparepart"
          }
        ]
      }
    ]
  }
];




export const TRAINING_MODULES = 
{
    "1": {
        "name": "Front Loading",
        "items": {
            "1": {
                "name": "Front Loading",
                "activity": "IKH",
                "task": "Melakukan kegiatan IKH (Instruksi Kerja Harian)",
                "point": 3,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 1,
                "point_severity": 3
            },
            "2": {
                "name": "Front Loading",
                "activity": "P5M",
                "task": "Menyampaikan safety information : potensi bahaya",
                "point": 3,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 1
            },
            "3": {
                "name": "Front Loading",
                "activity": "P5M",
                "task": "Cek kecukupan jam istirahat",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "4": {
                "name": "Front Loading",
                "activity": "P5M",
                "task": "Fatigue test : Romberg test",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "5": {
                "name": "Front Loading",
                "activity": "P5M",
                "task": "Menyampaikan production information : operational production",
                "point": 6,
                "category_safety": false,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "6": {
                "name": "Front Loading",
                "activity": "APD (PPE)",
                "task": "Memilih APD (PPE)  yang sesuai",
                "point": 3,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 1
            },
            "7": {
                "name": "Front Loading",
                "activity": "APD (PPE)",
                "task": "Soal mengenai APD ketika masuk workshop (tambahan kacamata & earplug)",
                "point": 1,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 1,
                "point_severity": 1
            },
            "8": {
                "name": "Front Loading",
                "activity": "Area Parkir",
                "task": "Memilih area parkir LV",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "9": {
                "name": "Front Loading",
                "activity": "Grade Box",
                "task": "Membaca informasi grade box",
                "point": 6,
                "category_safety": false,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "10": {
                "name": "Front Loading",
                "activity": "Pita Survey",
                "task": "Membaca informasi pita survey",
                "point": 6,
                "category_safety": false,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "11": {
                "name": "Front Loading",
                "activity": "Lantai Front",
                "task": "Melakukan perbaikan lantai front kerja",
                "point": 4,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 2
            },
            "12": {
                "name": "Front Loading",
                "activity": "Bench Loading",
                "task": "Melakukan perbaikan kondisi bench tinggi & lebar",
                "point": 4,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 2
            },
            "13": {
                "name": "Front Loading",
                "activity": "Kondisi Tebing",
                "task": "Melakukan perbaikan kondisi tebing (high risk)",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "14": {
                "name": "Front Loading",
                "activity": "Antrian Truck",
                "task": "Menginstruksikan antrian HD sesuai standar",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "15": {
                "name": "Front Loading",
                "activity": "Manuver Truck",
                "task": "Menginstruksikan  manuver HD sesuai standar",
                "point": 4,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 2
            },
            "16": {
                "name": "Front Loading",
                "activity": "Muatan Truck",
                "task": "Memilih muatan HD yang optimal",
                "point": 4,
                "category_safety": false,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 2
            },
            "17": {
                "name": "Front Loading",
                "activity": "Penarikan Unit Amblas",
                "task": "Memilih penanganan unit amblas di front",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 1,
                "point_severity": 2
            }
        }
    },
    "2": {
        "name": "Haul Road",
        "items": {
            "18": {
                "name": "Haul Road",
                "activity": "IKH",
                "task": "Melakukan kegiatan IKH (Instruksi Kerja Harian)",
                "point": 3,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 1,
                "point_severity": 3
            },
            "19": {
                "name": "Haul Road",
                "activity": "P5M",
                "task": "Menyampaikan safety information : potensi bahaya",
                "point": 3,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 1
            },
            "20": {
                "name": "Haul Road",
                "activity": "P5M",
                "task": "Cek kecukupan jam istirahat",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "21": {
                "name": "Haul Road",
                "activity": "P5M",
                "task": "Fatigue test : Romberg test",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "22": {
                "name": "Haul Road",
                "activity": "P5M",
                "task": "Menyampaikan production information : operational production",
                "point": 6,
                "category_safety": false,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "23": {
                "name": "Haul Road",
                "activity": "APD (PPE)",
                "task": "Memilih APD (PPE)  yang sesuai",
                "point": 3,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 1
            },
            "24": {
                "name": "Haul Road",
                "activity": "APD (PPE)",
                "task": "Soal mengenai APD ketika masuk workshop (tambahan kacamata & earplug)",
                "point": 1,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 1,
                "point_severity": 1
            },
            "25": {
                "name": "Haul Road",
                "activity": "Jalan Hauling",
                "task": "Memperbaiki jalan yang undulating",
                "point": 4,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 2
            },
            "26": {
                "name": "Haul Road",
                "activity": "Tanggul Jalan",
                "task": "Penunjukan tanggul yang tidak standar",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "27": {
                "name": "Haul Road",
                "activity": "Rambu",
                "task": "Inspeksi rambu jalan",
                "point": 3,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 1,
                "point_severity": 3
            },
            "28": {
                "name": "Haul Road",
                "activity": "Perbaikan Jalan",
                "task": "Perbaikan jalan mayor",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 1,
                "point_severity": 3
            },
            "29": {
                "name": "Haul Road",
                "activity": "Grader",
                "task": "Memilih pengoperasian grader yang benar",
                "point": 2,
                "category_safety": false,
                "category_production": true,
                "category_maintenance": true,
                "point_frequency": 1,
                "point_severity": 2
            },
            "30": {
                "name": "Haul Road",
                "activity": "Penyiraman WT",
                "task": "Pemilihan penyiraman putus putus WT",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "31": {
                "name": "Haul Road",
                "activity": "Unit Breakdown",
                "task": "Memberikan tanda adanya unit rusak",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 1,
                "point_severity": 2
            }
        }
    },
    "3": {
        "name": "Disposal",
        "items": {
            "32": {
                "name": "Disposal",
                "activity": "IKH",
                "task": "Melakukan kegiatan IKH (Instruksi Kerja Harian)",
                "point": 3,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 1,
                "point_severity": 3
            },
            "33": {
                "name": "Disposal",
                "activity": "P5M",
                "task": "Menyampaikan safety information : potensi bahaya",
                "point": 3,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 1
            },
            "34": {
                "name": "Disposal",
                "activity": "P5M",
                "task": "Cek kecukupan jam istirahat",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "35": {
                "name": "Disposal",
                "activity": "P5M",
                "task": "Fatigue test : Romberg test",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "36": {
                "name": "Disposal",
                "activity": "P5M",
                "task": "Menyampaikan production information : operational production",
                "point": 6,
                "category_safety": false,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "37": {
                "name": "Disposal",
                "activity": "APD (PPE)",
                "task": "Memilih APD (PPE)  yang sesuai",
                "point": 3,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 1
            },
            "38": {
                "name": "Disposal",
                "activity": "APD (PPE)",
                "task": "Soal mengenai APD ketika masuk workshop (tambahan kacamata & earplug)",
                "point": 1,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 1,
                "point_severity": 1
            },
            "39": {
                "name": "Disposal",
                "activity": "Grade Box",
                "task": "Membaca informasi grade box",
                "point": 6,
                "category_safety": false,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "40": {
                "name": "Disposal",
                "activity": "Boulder",
                "task": "Pemilihan penanganan boulder di disposal",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 1,
                "point_severity": 2
            },
            "41": {
                "name": "Disposal",
                "activity": "Tanggul",
                "task": "Pemilihan pembuatan tanggul yang standar",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "42": {
                "name": "Disposal",
                "activity": "Tower Lamp",
                "task": "Pemilihan pembuatan tanggul pengaman Tower lamp",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 1,
                "point_severity": 2
            },
            "43": {
                "name": "Disposal",
                "activity": "High Risk",
                "task": "Pemilihan penanganan disposal retak",
                "point": 3,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 1,
                "point_severity": 3
            },
            "44": {
                "name": "Disposal",
                "activity": "Batas Dumping",
                "task": "Pemilihan batas dumping yang benar untuk truck",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "45": {
                "name": "Disposal",
                "activity": "Jarak Dumping",
                "task": "Memilih jarak dumping yang aman antar unit truck",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            },
            "46": {
                "name": "Disposal",
                "activity": "Manuver Dumping",
                "task": "Menginstruksikan manuver dumping yang benar",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 3
            }
        }
    },
    "1001": {
        "name": "HD785-7: Bab 1 - Pengenalan Unit & Komponen",
        "items": {
            "1001": {
                "name": "HD785-7: Bab 1 - Pengenalan Unit & Komponen",
                "activity": "Kode Unit",
                "task": "Mengetahui arti dari setiap kode unit",
                "point": 1,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 1,
                "point_severity": 1
            },
            "1002": {
                "name": "HD785-7: Bab 1 - Pengenalan Unit & Komponen",
                "activity": "Komponen Unit",
                "task": "Mengetahui fungsi dari setiap komponen unit",
                "point": 2,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1003": {
                "name": "HD785-7: Bab 1 - Pengenalan Unit & Komponen",
                "activity": "Dimensi",
                "task": "Memahami dimensi dari unit komatsu HD785",
                "point": 4,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 2
            }
        }
    },
    "1002": {
        "name": "HD785-7: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
        "items": {
            "1004": {
                "name": "HD785-7: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Character Display",
                "task": "Mengetahui & memahami fungsi character display",
                "point": 2,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1005": {
                "name": "HD785-7: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Caution Item",
                "task": "Mengetahui & memahami fungsi caution item",
                "point": 4,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 2
            },
            "1006": {
                "name": "HD785-7: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Emergency Stop Item",
                "task": "Mengetahui & memahami fungsi emergency stop item",
                "point": 3,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 1,
                "point_severity": 3
            },
            "1007": {
                "name": "HD785-7: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Meter Display Portion",
                "task": "Mengetahui & memahami fungsi meter display portion",
                "point": 2,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1008": {
                "name": "HD785-7: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Switch & Indicator Lamp",
                "task": "Mengetahui & memahami fungsi switch & indicator lamp",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1009": {
                "name": "HD785-7: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Control Lever & Pedal",
                "task": "Mengetahui fungsi control & lever pada unit HD 785",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 2
            }
        }
    },
    "1003": {
        "name": "HD785-7: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
        "items": {
            "1010": {
                "name": "HD785-7: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Keliling Sebelum Engine Hidup",
                "task": "Memastikan kesiapan unit sebelum dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1011": {
                "name": "HD785-7: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Di Atas Alat Dan Di Dalam Kabin",
                "task": "Memastikan kesiapan unit sebelum dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1012": {
                "name": "HD785-7: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Setelah Menghidupkan Engine",
                "task": "Memastikan kesiapan unit sebelum dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1013": {
                "name": "HD785-7: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Setelah Selesai Operasi",
                "task": "Memastikan kondisi unit setelah dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            }
        }
    },
    "1004": {
        "name": "HD785-7: Bab 4 - Metode & Teknik Operasi",
        "items": {
            "1014": {
                "name": "HD785-7: Bab 4 - Metode & Teknik Operasi",
                "activity": "Blind Spot",
                "task": "Memahami area blind spot pada unit",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": false,
                "point_frequency": 1,
                "point_severity": 2
            },
            "1015": {
                "name": "HD785-7: Bab 4 - Metode & Teknik Operasi",
                "activity": "Posisi Antri & Manuver",
                "task": "Memahami tata cara prosedur terkait antri & manuver unit",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1016": {
                "name": "HD785-7: Bab 4 - Metode & Teknik Operasi",
                "activity": "Hauling",
                "task": "Memahami tata cara prosedur Hauling material",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1017": {
                "name": "HD785-7: Bab 4 - Metode & Teknik Operasi",
                "activity": "Dumping",
                "task": "Memahami tata cara prosedur dumping material",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1018": {
                "name": "HD785-7: Bab 4 - Metode & Teknik Operasi",
                "activity": "Parkir",
                "task": "Memahami prosedur tata cara parkir unit",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1019": {
                "name": "HD785-7: Bab 4 - Metode & Teknik Operasi",
                "activity": "Mis-operasi",
                "task": "Mengetahui & memahami langkah-langkah untuk mencegah mis operasi",
                "point": 3,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 1,
                "point_severity": 3
            }
        }
    },
    "1005": {
        "name": "D155A-6: Bab 1 - Pengenalan Unit & Komponen",
        "items": {
            "1020": {
                "name": "D155A-6: Bab 1 - Pengenalan Unit & Komponen",
                "activity": "Kode Unit",
                "task": "Mengetahui arti dari setiap kode unit",
                "point": 1,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 1,
                "point_severity": 1
            },
            "1021": {
                "name": "D155A-6: Bab 1 - Pengenalan Unit & Komponen",
                "activity": "Komponen Unit",
                "task": "Mengetahui fungsi dari setiap komponen unit",
                "point": 2,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1022": {
                "name": "D155A-6: Bab 1 - Pengenalan Unit & Komponen",
                "activity": "Basic Engine System",
                "task": "Mengetahui & memahami dari suatu basic engine system",
                "point": 2,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            }
        }
    },
    "1006": {
        "name": "D155A-6: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
        "items": {
            "1023": {
                "name": "D155A-6: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Kelompok Monitor",
                "task": "Mengetahui & memahami fungsi kelompok monitor",
                "point": 2,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1024": {
                "name": "D155A-6: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Multi Gauge Monitor",
                "task": "Mengetahui & memahami fungsi multi gauge monitor",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 1,
                "point_severity": 2
            },
            "1025": {
                "name": "D155A-6: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Switch Control",
                "task": "Mengetahui & memahami fungsi switch control",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1026": {
                "name": "D155A-6: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Control Lever & Pedal",
                "task": "Mengetahui & memahami fungsi control lever & pedal",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 2
            }
        }
    },
    "1007": {
        "name": "D155A-6: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
        "items": {
            "1027": {
                "name": "D155A-6: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Keliling Sebelum Engine Hidup",
                "task": "Memastikan kesiapan unit sebelum dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1028": {
                "name": "D155A-6: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Di Atas Alat Dan Di Dalam Kabin",
                "task": "Memastikan kesiapan unit sebelum dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1029": {
                "name": "D155A-6: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Setelah Menghidupkan Engine",
                "task": "Memastikan kesiapan unit sebelum dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1030": {
                "name": "D155A-6: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Setelah Selesai Operasi",
                "task": "Memastikan kondisi unit setelah dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            }
        }
    },
    "1008": {
        "name": "D155A-6: Bab 4 - Metode & Teknik Operasi",
        "items": {
            "1031": {
                "name": "D155A-6: Bab 4 - Metode & Teknik Operasi",
                "activity": "Gerakan Dozing Dan Ripper",
                "task": "Memahami metode dozing & penggunaan ripper saat pengoperasian bulldozer",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1032": {
                "name": "D155A-6: Bab 4 - Metode & Teknik Operasi",
                "activity": "Spreading",
                "task": "Memahami metode spreading material saat pengoperasian bulldozer",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1033": {
                "name": "D155A-6: Bab 4 - Metode & Teknik Operasi",
                "activity": "Scraping",
                "task": "Memahami metode scraping material saat pengoperasian bulldozer",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1034": {
                "name": "D155A-6: Bab 4 - Metode & Teknik Operasi",
                "activity": "Membuat Tanggul",
                "task": "Memahami prosedur aktifitas pembuatan tanggul ",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1035": {
                "name": "D155A-6: Bab 4 - Metode & Teknik Operasi",
                "activity": "Ripping",
                "task": "Memahami prosedur aktifitas melakukan ripping",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1036": {
                "name": "D155A-6: Bab 4 - Metode & Teknik Operasi",
                "activity": "Parkir",
                "task": "Memahami prosedur parkir antar unit yang benar",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            }
        }
    },
    "1009": {
        "name": "PC200-8: Bab 1 - Pengenalan Unit & Komponen",
        "items": {
            "1037": {
                "name": "PC200-8: Bab 1 - Pengenalan Unit & Komponen",
                "activity": "Kode Unit",
                "task": "Mengetahui arti dari setiap kode unit",
                "point": 1,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 1,
                "point_severity": 1
            },
            "1038": {
                "name": "PC200-8: Bab 1 - Pengenalan Unit & Komponen",
                "activity": "Komponen Unit",
                "task": "Mengetahui fungsi dari setiap komponen unit",
                "point": 2,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1039": {
                "name": "PC200-8: Bab 1 - Pengenalan Unit & Komponen",
                "activity": "Basic Engine System",
                "task": "Mengetahui & memahami dari suatu basic engine system",
                "point": 4,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 2
            }
        }
    },
    "1010": {
        "name": "PC200-8: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
        "items": {
            "1040": {
                "name": "PC200-8: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Kelompok Monitor",
                "task": "Mengetahui & memahami fungsi kelompok monitor",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1041": {
                "name": "PC200-8: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Basic Check Item",
                "task": "Mengetahui & memahami fungsi basic check item",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1042": {
                "name": "PC200-8: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Caution Item",
                "task": "Mengetahui & memahami fungsi caution item",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1043": {
                "name": "PC200-8: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Emergency Stop Item",
                "task": "Mengetahui & memahami fungsi emergency stop item",
                "point": 3,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 1,
                "point_severity": 3
            },
            "1044": {
                "name": "PC200-8: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Display Portion",
                "task": "Mengetahui & memahami fungsi display portion",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1045": {
                "name": "PC200-8: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Switch",
                "task": "Mengetahui & memahami fungsi switch pada instrumen unit",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1046": {
                "name": "PC200-8: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Control Lever & Pedal",
                "task": "Mengetahui & memahami fungsi control lever & pedal",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 2
            }
        }
    },
    "1011": {
        "name": "PC200-8: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
        "items": {
            "1047": {
                "name": "PC200-8: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Keliling Sebelum Engine Hidup",
                "task": "Memastikan kesiapan unit sebelum dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1048": {
                "name": "PC200-8: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Di Atas Alat Dan Di Dalam kabin",
                "task": "Memastikan kesiapan unit sebelum dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1049": {
                "name": "PC200-8: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Setelah Menghidupkan Engine",
                "task": "Memastikan kesiapan unit sebelum dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1050": {
                "name": "PC200-8: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Setelah Selesai Operasi",
                "task": "Memastikan kondisi unit setelah dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            }
        }
    },
    "1012": {
        "name": "PC200-8: Bab 4 - Metode & Teknik Operasi",
        "items": {
            "1051": {
                "name": "PC200-8: Bab 4 - Metode & Teknik Operasi",
                "activity": "Proses Loading",
                "task": "Memahami tata cara proses Loading material",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1052": {
                "name": "PC200-8: Bab 4 - Metode & Teknik Operasi",
                "activity": "Pembuatan Tanggul",
                "task": "Memahami tata cara proses pembuatan tanggul",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1053": {
                "name": "PC200-8: Bab 4 - Metode & Teknik Operasi",
                "activity": "Naik Ke Unit Lowboy",
                "task": "Memahami prosedur menaikkan unit ke atas lowboy",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1054": {
                "name": "PC200-8: Bab 4 - Metode & Teknik Operasi",
                "activity": "Turun Dari Unit Lowboy",
                "task": "Memahami prosedur menurunkan unit dari atas lowboy",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1055": {
                "name": "PC200-8: Bab 4 - Metode & Teknik Operasi",
                "activity": "Parkir",
                "task": "Memahami prosedur tata cara parkir unit",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1056": {
                "name": "PC200-8: Bab 4 - Metode & Teknik Operasi",
                "activity": "Mis-operasi",
                "task": "Mengetahui & memahami langkah-langkah untuk mencegah mis operasi",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 2
            }
        }
    },
    "1013": {
        "name": "FMX440: Bab 1 - Pengenalan Unit & Komponen",
        "items": {
            "1057": {
                "name": "FMX440: Bab 1 - Pengenalan Unit & Komponen",
                "activity": "Kode Unit",
                "task": "Mengetahui arti dari setiap kode unit",
                "point": 1,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 1,
                "point_severity": 1
            },
            "1058": {
                "name": "FMX440: Bab 1 - Pengenalan Unit & Komponen",
                "activity": "Komponen Unit",
                "task": "Mengetahui fungsi dari setiap komponen unit",
                "point": 2,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1059": {
                "name": "FMX440: Bab 1 - Pengenalan Unit & Komponen",
                "activity": "Dimensi",
                "task": "Memahami dimensi dari unit FMX440",
                "point": 4,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 2
            }
        }
    },
    "1014": {
        "name": "FMX440: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
        "items": {
            "1060": {
                "name": "FMX440: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Indicator Lamp",
                "task": "Mengetahui & memahami fungsi switch & indicator lamp",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1061": {
                "name": "FMX440: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Gauge",
                "task": "Mengetahui & memahami fungsi Gauge Meter",
                "point": 4,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 2
            },
            "1062": {
                "name": "FMX440: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Switch & Indicator Lamp",
                "task": "Mengetahui & memahami fungsi switch & indicator lamp",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1063": {
                "name": "FMX440: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Control Lever & Pedal",
                "task": "Mengetahui fungsi control & lever pada unit FMX440",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 2
            }
        }
    },
    "1015": {
        "name": "FMX440: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
        "items": {
            "1064": {
                "name": "FMX440: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Keliling Sebelum Engine Hidup",
                "task": "Memastikan kesiapan unit sebelum dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1065": {
                "name": "FMX440: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Di Atas Alat Dan Di Dalam Kabin",
                "task": "Memastikan kesiapan unit sebelum dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1066": {
                "name": "FMX440: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Setelah Menghidupkan Engine",
                "task": "Memastikan kesiapan unit sebelum dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1067": {
                "name": "FMX440: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Setelah Selesai Operasi",
                "task": "Memastikan kondisi unit setelah dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            }
        }
    },
    "1016": {
        "name": "FMX440: Bab 4 - Metode & Teknik Operasi",
        "items": {
            "1068": {
                "name": "FMX440: Bab 4 - Metode & Teknik Operasi",
                "activity": "Posisi Antri & Manuver",
                "task": "Memahami tata cara prosedur terkait antri & manuver unit",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1069": {
                "name": "FMX440: Bab 4 - Metode & Teknik Operasi",
                "activity": "Hauling",
                "task": "Memahami tata cara prosedur Hauling material",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1070": {
                "name": "FMX440: Bab 4 - Metode & Teknik Operasi",
                "activity": "Dumping",
                "task": "Memahami tata cara prosedur dumping material",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1071": {
                "name": "FMX440: Bab 4 - Metode & Teknik Operasi",
                "activity": "Pasca Penyiraman",
                "task": "Memahami prosedur pengoperasian pasca penyiraman jalan",
                "point": 3,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 1,
                "point_severity": 3
            },
            "1072": {
                "name": "FMX440: Bab 4 - Metode & Teknik Operasi",
                "activity": "Parkir",
                "task": "Memahami prosedur tata cara parkir unit",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1073": {
                "name": "FMX440: Bab 4 - Metode & Teknik Operasi",
                "activity": "Emergency Respon",
                "task": "Memahami prosedur penanganan Emergency respon",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 3,
                "point_severity": 2
            },
            "1074": {
                "name": "FMX440: Bab 4 - Metode & Teknik Operasi",
                "activity": "Mis Operasi",
                "task": "Mengetahui & memahami langkah - langkah untuk mencegah mis operasi",
                "point": 3,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 1,
                "point_severity": 3
            }
        }
    },
    "1017": {
        "name": "MB4054: Bab 1 - Pengenalan Unit & Komponen",
        "items": {
            "1075": {
                "name": "MB4054: Bab 1 - Pengenalan Unit & Komponen",
                "activity": "Kode Unit",
                "task": "Mengetahui arti dari setiap kode unit",
                "point": 1,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 1,
                "point_severity": 1
            },
            "1076": {
                "name": "MB4054: Bab 1 - Pengenalan Unit & Komponen",
                "activity": "Komponen Unit",
                "task": "Mengetahui fungsi dari setiap komponen unit",
                "point": 2,
                "category_safety": false,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1077": {
                "name": "MB4054: Bab 1 - Pengenalan Unit & Komponen",
                "activity": "Dimensi",
                "task": "Memahami dimensi dari unit MB4054",
                "point": 4,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": false,
                "point_frequency": 2,
                "point_severity": 2
            }
        }
    },
    "1018": {
        "name": "MB4054: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
        "items": {
            "1078": {
                "name": "MB4054: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Indicator Lamp",
                "task": "Mengetahui & memahami fungsi switch & indicator lamp",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1079": {
                "name": "MB4054: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Display Monitor",
                "task": "Mengetahui & memahami fungsi Display Monitor",
                "point": 4,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 2
            },
            "1080": {
                "name": "MB4054: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Gauge",
                "task": "Mengetahui & memahami fungsi Gauge Meter",
                "point": 2,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 1
            },
            "1081": {
                "name": "MB4054: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Switch & Indicator Lamp",
                "task": "Mengetahui & memahami fungsi switch & indicator lamp",
                "point": 4,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 2
            },
            "1082": {
                "name": "MB4054: Bab 2 - Pemahaman Instrumen Panel & Kontrol",
                "activity": "Control Lever & Pedal",
                "task": "Mengetahui fungsi control & lever pada unit MB4054",
                "point": 6,
                "category_safety": true,
                "category_production": true,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 2
            }
        }
    },
    "1019": {
        "name": "MB4054: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
        "items": {
            "1083": {
                "name": "MB4054: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Keliling Sebelum Engine Hidup",
                "task": "Memastikan kesiapan unit sebelum dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1084": {
                "name": "MB4054: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Di Atas Alat Dan Di Dalam Kabin",
                "task": "Memastikan kesiapan unit sebelum dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1085": {
                "name": "MB4054: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Setelah Menghidupkan Engine",
                "task": "Memastikan kesiapan unit sebelum dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1086": {
                "name": "MB4054: Bab 3 - Pelaksanaan Perawatan Harian (P2H)",
                "activity": "Periksa Setelah Selesai Operasi",
                "task": "Memastikan kondisi unit setelah dioperasikan",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            }
        }
    },
    "1020": {
        "name": "MB4054: Bab 4 - Metode & Teknik Operasi",
        "items": {
            "1087": {
                "name": "MB4054: Bab 4 - Metode & Teknik Operasi",
                "activity": "Emergency Respond Fire Case",
                "task": "Memahami prosedur penanganan kondisi darurat saat terjadi kebakaran",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 3
            },
            "1088": {
                "name": "MB4054: Bab 4 - Metode & Teknik Operasi",
                "activity": "Emergency Respond Brake System",
                "task": "Memahami prosedur penanganan kondisi darurat saat terjadi kegagalan sistem Pengereman",
                "point": 6,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 2,
                "point_severity": 3
            },
            "1089": {
                "name": "MB4054: Bab 4 - Metode & Teknik Operasi",
                "activity": "Emergency Respond Hazard Identification",
                "task": "Memahami prosedur penanganan kondisi darurat saat kondisi tidak aman",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            },
            "1090": {
                "name": "MB4054: Bab 4 - Metode & Teknik Operasi",
                "activity": "Emergency Respond Trouble Case",
                "task": "Memahami prosedur penanganan kondisi darurat saat terjadi masalah pada unit",
                "point": 9,
                "category_safety": true,
                "category_production": false,
                "category_maintenance": true,
                "point_frequency": 3,
                "point_severity": 3
            }
        }
    },



    
    "2001": {
      "name": "HD785-7: Bab 1 - Akses Peralatan",
      "items": {
        "2001": {
          "name": "HD785-7: Bab 1 - Akses Peralatan",
          "activity": "Alat Pelindung Diri",
          "task": "Mengetahui Alat Pelindung Diri yang digunakan oleh Mekanik",
          "point": 4,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 2,
          "point_severity": 2
        },
        "2002": {
          "name": "HD785-7: Bab 1 - Akses Peralatan",
          "activity": "Langkah Isolasi (akses peralatan)",
          "task": "Mengetahui urutan 6 langkah isolasi (akses peralatan)",
          "point": 4,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 2,
          "point_severity": 2
        },
        "2003": {
          "name": "HD785-7: Bab 1 - Akses Peralatan",
          "activity": "Langkah Isolasi (pemulihan peralatan)",
          "task": "Mengetahui urutan 6 langkah isolasi (pemulihan peralatan)",
          "point": 4,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 2,
          "point_severity": 2
        }
      }
    },
    "2002": {
      "name": "HD785-7: Bab 2 - Spesifikasi & Feature",
      "items": {
        "2004": {
          "name": "HD785-7: Bab 2 - Spesifikasi & Feature",
          "activity": "Kode Unit",
          "task": "Mengetahui & memahami arti dari kode Unit",
          "point": 1,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 1
        },
        "2005": {
          "name": "HD785-7: Bab 2 - Spesifikasi & Feature",
          "activity": "Kode Engine",
          "task": "Mengetahui & memahami arti dari kode Engine",
          "point": 1,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 1
        },
        "2006": {
          "name": "HD785-7: Bab 2 - Spesifikasi & Feature",
          "activity": "Dimensi Unit",
          "task": "Mengetahui dimensi unit",
          "point": 1,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 1
        }
      }
    },
    "2003": {
      "name": "HD785-7: Bab 3 - Pengenalan Komponen Rute 1-3",
      "items": {
        "2007": {
          "name": "HD785-7: Bab 3 - Pengenalan Komponen Rute 1-3",
          "activity": "Periksa Komponen pada Rute 1",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 1",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 2,
          "point_severity": 1
        },
        "2008": {
          "name": "HD785-7: Bab 3 - Pengenalan Komponen Rute 1-3",
          "activity": "Periksa Komponen pada Rute 2",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 2",
          "point": 3,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 3,
          "point_severity": 1
        },
        "2009": {
          "name": "HD785-7: Bab 3 - Pengenalan Komponen Rute 1-3",
          "activity": "Periksa Komponen pada Rute 3",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 3",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 2,
          "point_severity": 1
        }
      }
    },
    "2004": {
      "name": "HD785-7: Bab 4 - Pengenalan Komponen Rute 4-6",
      "items": {
        "2010": {
          "name": "HD785-7: Bab 4 - Pengenalan Komponen Rute 4-6",
          "activity": "Periksa Komponen pada Rute 4",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 4",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 2,
          "point_severity": 1
        },
        "2011": {
          "name": "HD785-7: Bab 4 - Pengenalan Komponen Rute 4-6",
          "activity": "Periksa Komponen pada Rute 5",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 5",
          "point": 3,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 3,
          "point_severity": 1
        },
        "2012": {
          "name": "HD785-7: Bab 4 - Pengenalan Komponen Rute 4-6",
          "activity": "Periksa Komponen pada Rute 6",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 6",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 2,
          "point_severity": 1
        }
      }
    },
    "2005": {
      "name": "HD785-7: Bab 5 - Pengenalan Komponen Rute 7-9",
      "items": {
        "2013": {
          "name": "HD785-7: Bab 5 - Pengenalan Komponen Rute 7-9",
          "activity": "Periksa Komponen pada Rute 7",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 7",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 2
        },
        "2014": {
          "name": "HD785-7: Bab 5 - Pengenalan Komponen Rute 7-9",
          "activity": "Periksa Komponen pada Rute 8",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 8",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 2
        },
        "2015": {
          "name": "HD785-7: Bab 5 - Pengenalan Komponen Rute 7-9",
          "activity": "Periksa Komponen pada Rute 9",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 9",
          "point": 3,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 3
        }
      }
    },
    "2006": {
      "name": "HD785-7: Bab 6 - Pengenalan Komponen Rute 10-12",
      "items": {
        "2016": {
          "name": "HD785-7: Bab 6 - Pengenalan Komponen Rute 10-12",
          "activity": "Periksa Komponen pada Rute 10",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 10",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 2
        },
        "2017": {
          "name": "HD785-7: Bab 6 - Pengenalan Komponen Rute 10-12",
          "activity": "Periksa Komponen pada Rute 11",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 11",
          "point": 3,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 3
        },
        "2018": {
          "name": "HD785-7: Bab 6 - Pengenalan Komponen Rute 10-12",
          "activity": "Periksa Komponen pada Rute 12",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 12",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 2
        }
      }
    },


    
    "2007": {
      "name": "PC2000-8: Bab 1 - Akses Peralatan",
      "items": {
        "2019": {
          "name": "PC2000-8: Bab 1 - Akses Peralatan",
          "activity": "Alat Pelindung Diri",
          "task": "Mengetahui Alat Pelindung Diri yang digunakan oleh Mekanik",
          "point": 4,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 2,
          "point_severity": 2
        },
        "2020": {
          "name": "PC2000-8: Bab 1 - Akses Peralatan",
          "activity": "Langkah Isolasi (akses peralatan)",
          "task": "Mengetahui urutan 6 langkah isolasi (akses peralatan)",
          "point": 4,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 2,
          "point_severity": 2
        },
        "2021": {
          "name": "PC2000-8: Bab 1 - Akses Peralatan",
          "activity": "Langkah Isolasi (pemulihan peralatan)",
          "task": "Mengetahui urutan 6 langkah isolasi (pemulihan peralatan)",
          "point": 4,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 2,
          "point_severity": 2
        }
      }
    },
    "2008": {
      "name": "PC2000-8: Bab 2 - Spesifikasi & Feature",
      "items": {
        "2022": {
          "name": "PC2000-8: Bab 2 - Spesifikasi & Feature",
          "activity": "Kode Unit",
          "task": "Mengetahui & memahami arti dari kode Unit",
          "point": 1,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 1
        },
        "2023": {
          "name": "PC2000-8: Bab 2 - Spesifikasi & Feature",
          "activity": "Kode Engine",
          "task": "Mengetahui & memahami arti dari kode Engine",
          "point": 1,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 1
        }
      }
    },
    "2009": {
      "name": "PC2000-8: Bab 3 - Pengenalan Komponen Rute 1-3",
      "items": {
        "2024": {
          "name": "PC2000-8: Bab 3 - Pengenalan Komponen Rute 1-3",
          "activity": "Periksa Komponen pada Rute 1",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 1",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 2,
          "point_severity": 1
        },
        "2025": {
          "name": "PC2000-8: Bab 3 - Pengenalan Komponen Rute 1-3",
          "activity": "Periksa Komponen pada Rute 2",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 2",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 2,
          "point_severity": 1
        },
        "2026": {
          "name": "PC2000-8: Bab 3 - Pengenalan Komponen Rute 1-3",
          "activity": "Periksa Komponen pada Rute 3",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 3",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 2,
          "point_severity": 1
        }
      }
    },
    "2010": {
      "name": "PC2000-8: Bab 4 - Pengenalan Komponen Rute 4-6",
      "items": {
        "2027": {
          "name": "PC2000-8: Bab 4 - Pengenalan Komponen Rute 4-6",
          "activity": "Periksa Komponen pada Rute 4",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 4",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 2,
          "point_severity": 1
        },
        "2028": {
          "name": "PC2000-8: Bab 4 - Pengenalan Komponen Rute 4-6",
          "activity": "Periksa Komponen pada Rute 5",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 5",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 2,
          "point_severity": 1
        },
        "2029": {
          "name": "PC2000-8: Bab 4 - Pengenalan Komponen Rute 4-6",
          "activity": "Periksa Komponen pada Rute 6",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 6",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 2,
          "point_severity": 1
        }
      }
    },
    "2011": {
      "name": "PC2000-8: Bab 5 - Pengenalan Komponen Rute 7-9",
      "items": {
        "2030": {
          "name": "PC2000-8: Bab 5 - Pengenalan Komponen Rute 7-9",
          "activity": "Periksa Komponen pada Rute 7",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 7",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 2
        },
        "2031": {
          "name": "PC2000-8: Bab 5 - Pengenalan Komponen Rute 7-9",
          "activity": "Periksa Komponen pada Rute 8",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 8",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 2
        },
        "2032": {
          "name": "PC2000-8: Bab 5 - Pengenalan Komponen Rute 7-9",
          "activity": "Periksa Komponen pada Rute 9",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 9",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 2
        }
      }
    },
    "2012": {
      "name": "PC2000-8: Bab 6 - Pengenalan Komponen Rute 10-12",
      "items": {
        "2033": {
          "name": "PC2000-8: Bab 6 - Pengenalan Komponen Rute 10-12",
          "activity": "Periksa Komponen pada Rute 10",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 10",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 2
        },
        "2034": {
          "name": "PC2000-8: Bab 6 - Pengenalan Komponen Rute 10-12",
          "activity": "Periksa Komponen pada Rute 11",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 11",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 2
        },
        "2035": {
          "name": "PC2000-8: Bab 6 - Pengenalan Komponen Rute 10-12",
          "activity": "Periksa Komponen pada Rute 12",
          "task": "Memahami Nama & Fungsi Komponen pada Rute 12",
          "point": 2,
          "category_safety": false,
          "category_production": false,
          "category_maintenance": true,
          "point_frequency": 1,
          "point_severity": 2
        }
      }
    },



    
    "3001": {
      "name": "Fire Emergency: Bab 1 - APAR di Area Tambang",
      "items": {
        "3001": {
          "name": "Fire Emergency: Bab 1 - APAR di Area Tambang",
          "activity": "Penggunaan APAR",
          "task": "Memadamkan sumber api di unit hauler OB",
          "point": 16,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 4,
          "point_severity": 4
        }
      }
    },
    "3002": {
      "name": "Fire Emergency: Bab 2 - Fire Suppression di Area Tambang",
      "items": {
        "3002": {
          "name": "Fire Emergency: Bab 2 - Fire Suppression di Area Tambang",
          "activity": "Penggunaan Fire Supresion",
          "task": "Memadamkan sumber api di unit loader coal",
          "point": 16,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 4,
          "point_severity": 4
        }
      }
    },
    "3003": {
      "name": "Fire Emergency: Bab 3 - Area Bangunan",
      "items": {
        "3003": {
          "name": "Fire Emergency: Bab 3 - Area Bangunan",
          "activity": "Penggunaan APAR",
          "task": "Memadamkan sumber api pada bangunan gudang dokumen",
          "point": 16,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 4,
          "point_severity": 4
        }
      }
    },
    "3004": {
      "name": "Fire Emergency: Bab 4 - Area Power Genset",
      "items": {
        "3004": {
          "name": "Fire Emergency: Bab 4 - Area Power Genset",
          "activity": "Penggunaan APAR",
          "task": "Memadamkan sumber api pada area power genset",
          "point": 16,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 4,
          "point_severity": 4
        }
      }
    },
    "3005": {
      "name": "Fire Emergency: Bab 5 - Area Main Tank",
      "items": {
        "3005": {
          "name": "Fire Emergency: Bab 5 - Area Main Tank",
          "activity": "Penggunaan Hydrant",
          "task": "Memadamkan sumber api pada area maintank fuel",
          "point": 16,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 4,
          "point_severity": 4
        }
      }
    },


    "3006": {
      "name": "Medical Emergency: Bab 1 - Bantuan Hidup Dasar",
      "items": {
        "3006": {
          "name": "Medical Emergency: Bab 1 - Bantuan Hidup Dasar",
          "activity": "Bantuan Hidup Dasar",
          "task": "Korban pingsan dengan denyut nadi dan nafas normal",
          "point": 8,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 2,
          "point_severity": 4
        },
        "3007": {
          "name": "Medical Emergency: Bab 1 - Bantuan Hidup Dasar",
          "activity": "Bantuan Hidup Dasar",
          "task": "Korban pingsan dengan denyut nadi tidak ada dan nafas normal",
          "point": 8,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 2,
          "point_severity": 4
        },
        "3008": {
          "name": "Medical Emergency: Bab 1 - Bantuan Hidup Dasar",
          "activity": "Bantuan Hidup Dasar",
          "task": "Korban pingsan dengan denyut nadi tidak ada dan tidak ada nafas",
          "point": 8,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 2,
          "point_severity": 4
        }
      }
    },

    "3007": {
      "name": "Medical Emergency: Bab 2 - Patah Tulang",
      "items": {
        "3009": {
          "name": "Medical Emergency: Bab 2 - Patah Tulang",
          "activity": "Patah Tulang",
          "task": "Korban patah tulang di bagian tangan kanan",
          "point": 8,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 2,
          "point_severity": 4
        }
      }
    },

    "3008": {
      "name": "Medical Emergency: Bab 3 - Luka Bakar & Pendarahan",
      "items": {
        "3010": {
          "name": "Medical Emergency: Bab 3 - Luka Bakar & Pendarahan",
          "activity": "Luka Bakar & Pendarahan",
          "task": "Karyawan mengalami luka bakar pada saat pekerjaan pemeriksaan air radiator di unit HD 785",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 4
        },
        "3011": {
          "name": "Medical Emergency: Bab 3 - Luka Bakar & Pendarahan",
          "activity": "Luka Bakar & Pendarahan",
          "task": "Karyawan mengalami luka berakibat perdarahan di bagian ibu jari bagian kanan saat menutup pintu HD 785",
          "point": 6,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 2,
          "point_severity": 3
        }
      }
    },

    "3009": {
      "name": "Medical Emergency: Bab 4 - Tersedak",
      "items": {
        "3012": {
          "name": "Medical Emergency: Bab 4 - Tersedak",
          "activity": "Tersedak",
          "task": "Karyawan mengalami tersedak pada saat makan siang diarea office",
          "point": 8,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 4,
          "point_severity": 2
        }
      }
    },

    "3010": {
      "name": "Risk Identification: Bab 1 - Area Front Loading",
      "items": {
        "3013": {
          "name": "Risk Identification: Bab 1 - Area Front Loading",
          "activity": "Situasi Berbahaya",
          "task": "Melakukan identifikasi pada lokasi parkir unit diarea front loading",
          "point": 16,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 4,
          "point_severity": 4
        },
        "3014": {
          "name": "Risk Identification: Bab 1 - Area Front Loading",
          "activity": "Orang Bekerja Tidak Aman",
          "task": "Melihat Operator unit lain (HD) tertidur saat melakukan loading",
          "point": 16,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 4,
          "point_severity": 4
        },
        "3015": {
          "name": "Risk Identification: Bab 1 - Area Front Loading",
          "activity": "Peralatan Membahayakan",
          "task": "Rotari Lamp unit HD lain mati",
          "point": 16,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 4,
          "point_severity": 4
        },
        "3016": {
          "name": "Risk Identification: Bab 1 - Area Front Loading",
          "activity": "Kondisi Tidak Aman",
          "task": "Sisi kanan front loading terdapat genangan air",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 4,
          "point_severity": 3
        },
        "3017": {
          "name": "Risk Identification: Bab 1 - Area Front Loading",
          "activity": "Tindakan Tidak Aman",
          "task": "MT yang sedang melakukan orientasi lapangan dan berdiri di atas tebing melakukan observasi terhadap proses loading",
          "point": 16,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 4,
          "point_severity": 4
        }
      }
    },

    "3011": {
      "name": "Risk Identification: Bab 2 - Area Jalan Hauling",
      "items": {
        "3018": {
          "name": "Risk Identification: Bab 2 - Area Jalan Hauling",
          "activity": "Kondisi Tidak Aman",
          "task": "Bundwall / tanggul yang tidak sesuai dengan standar",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 4
        },
        "3019": {
          "name": "Risk Identification: Bab 2 - Area Jalan Hauling",
          "activity": "Kondisi Tidak Aman",
          "task": "Kondisi jalan dalam kondisi licin pasca penyiraman",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 4,
          "point_severity": 3
        },
        "3020": {
          "name": "Risk Identification: Bab 2 - Area Jalan Hauling",
          "activity": "Kondisi Tidak Aman",
          "task": "Pertigaan dengan kondisi blindspot tanpa dilengkapi rambu maupun cermin cembung",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 4
        },
        "3021": {
          "name": "Risk Identification: Bab 2 - Area Jalan Hauling",
          "activity": "Kondisi Tidak Aman",
          "task": "Grade jalan hauling tidak standard",
          "point": 9,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 3
        },
        "3022": {
          "name": "Risk Identification: Bab 2 - Area Jalan Hauling",
          "activity": "Situasi Berbahaya",
          "task": "Unit HD yang berhenti di pinggir jalan tanpa ada isyarat peringatan",
          "point": 16,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 4,
          "point_severity": 4
        }
      }
    },

    "3012": {
      "name": "Risk Identification: Bab 3 - Area Disposal",
      "items": {
        "3023": {
          "name": "Risk Identification: Bab 3 - Area Disposal",
          "activity": "Orang Bekerja Tidak Aman",
          "task": "Spotter berdiri terlalu dekat dengan unit dozer yang sedang beroperasi",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 4
        },
        "3024": {
          "name": "Risk Identification: Bab 3 - Area Disposal",
          "activity": "Kondisi Tidak Aman",
          "task": "Terdapat retakan di area disposal",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 4
        },
        "3025": {
          "name": "Risk Identification: Bab 3 - Area Disposal",
          "activity": "Tindakan Tidak Aman",
          "task": "Operator dumping material dengan jarak dumping yang tidak sesuai dengan material disposal",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 4
        },
        "3026": {
          "name": "Risk Identification: Bab 3 - Area Disposal",
          "activity": "Situasi Berbahaya",
          "task": "Penerangan tidak memadai karena lokasi tower lamp jauh atau tower lamp mati",
          "point": 16,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 4,
          "point_severity": 4
        },
        "3027": {
          "name": "Risk Identification: Bab 3 - Area Disposal",
          "activity": "Tindakan Tidak Aman",
          "task": "Operator melakukan manuver tidak searah jarum jam",
          "point": 9,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 3
        }
      }
    },

    "3013": {
      "name": "Risk Identification: Bab 4 - Area Workshop",
      "items": {
        "3028": {
          "name": "Risk Identification: Bab 4 - Area Workshop",
          "activity": "Kondisi Tidak Aman",
          "task": "Overhead crane dengan kondisi webbing sling dalam kondisi tidak layak",
          "point": 9,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 3
        },
        "3029": {
          "name": "Risk Identification: Bab 4 - Area Workshop",
          "activity": "Kondisi Tidak Aman",
          "task": "HD yang terparkir dan sedang dilakukan perbaikan tidak terpasang LOTO",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 4
        },
        "3030": {
          "name": "Risk Identification: Bab 4 - Area Workshop",
          "activity": "Tindakan Tidak Aman",
          "task": "Mekanik yang melakukan pengencangan baut menggunakan torque wrench tapi tidak menggunakan APD pelindung telinga",
          "point": 9,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 3
        },
        "3031": {
          "name": "Risk Identification: Bab 4 - Area Workshop",
          "activity": "Situasi Berbahaya",
          "task": "Forklift yang melintas di jalur berjalan",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 4
        },
        "3032": {
          "name": "Risk Identification: Bab 4 - Area Workshop",
          "activity": "Situasi Berbahaya",
          "task": "HD yang sedang diperbaiki, vessel yang terangkat tidak di pasang safety lock",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 4
        }
      }
    },

    "3014": {
      "name": "Risk Identification: Bab 5 - Area Fabrikasi",
      "items": {
        "3033": {
          "name": "Risk Identification: Bab 5 - Area Fabrikasi",
          "activity": "Tindakan Tidak Aman",
          "task": "Welder yang melakukan pengelasan namun tidak menggunakan apron",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 4
        },
        "3034": {
          "name": "Risk Identification: Bab 5 - Area Fabrikasi",
          "activity": "Kondisi Tidak Aman",
          "task": "Welding tidak tertutup oleh tabir dan ada operator yang melintas dekat dengan area tersebut tanpa menggunakan kacamata",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 4
        },
        "3035": {
          "name": "Risk Identification: Bab 5 - Area Fabrikasi",
          "activity": "Kondisi Tidak Aman",
          "task": "Tangga beroda yang digunakan oleh welder dalam kondisi rodanya tidak di lock",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 4
        },
        "3036": {
          "name": "Risk Identification: Bab 5 - Area Fabrikasi",
          "activity": "Kondisi Tidak Aman",
          "task": "APAR pada Welding machine set dalam kondisi low pressure",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 4
        },
        "3037": {
          "name": "Risk Identification: Bab 5 - Area Fabrikasi",
          "activity": "Kondisi Tidak Aman",
          "task": "Terdapat kotak penyimpanan SDS tapi kosong",
          "point": 12,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 4
        }
      }
    },

    "3015": {
      "name": "Risk Identification: Bab 6 - Area Gudang Sparepart",
      "items": {
        "3038": {
          "name": "Risk Identification: Bab 6 - Area Gudang Sparepart",
          "activity": "Kondisi Tidak Aman",
          "task": "Kotak penyimpanan SDS di dekat penyimpanan oli dan bahan kimia tapi dalam kondisi kosong",
          "point": 4,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 2,
          "point_severity": 2
        },
        "3039": {
          "name": "Risk Identification: Bab 6 - Area Gudang Sparepart",
          "activity": "Kondisi Tidak Aman",
          "task": "Spare part yang baru datang ditumpuk pada area berjalan",
          "point": 9,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 3
        },
        "3040": {
          "name": "Risk Identification: Bab 6 - Area Gudang Sparepart",
          "activity": "Kondisi Tidak Aman",
          "task": "Drum oli baru yang tidak dilengkapi dengan symbol B3",
          "point": 9,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 3
        },
        "3041": {
          "name": "Risk Identification: Bab 6 - Area Gudang Sparepart",
          "activity": "Kondisi Tidak Aman",
          "task": "Hook pada crane truck tanpa dilengkapi safety latch",
          "point": 9,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 3
        },
        "3042": {
          "name": "Risk Identification: Bab 6 - Area Gudang Sparepart",
          "activity": "Tindakan Tidak Aman",
          "task": "Forklift yang melintas dengan membawa tabung gas dengan posisi rebah",
          "point": 9,
          "category_safety": true,
          "category_production": false,
          "category_maintenance": false,
          "point_frequency": 3,
          "point_severity": 3
        }
      }
    }

};

import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Auth } from "aws-amplify";
import axios from "axios";
import * as CONSTANTS from "../constants";
import { useAppContext } from "../libs/contextLib";
import { Image, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useFormFields } from "../libs/hooksLib";
import LoaderButton from "../components/LoaderButton";
import { CForm, CFormFloating, CFormInput, CFormLabel, CFormSelect } from '@coreui/react';

const Register = (props) => {
  const { showNotification } = useAppContext();

  const history = useHistory();

  const passwordForm = useRef(null);
  const confirmPasswordForm = useRef(null);

  const [temporaryUser, setTemporaryUser] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [loginValidated, setLoginValidated] = useState(false);

  const [masterData, setMasterData] = useState(null);

  const [isModalShowing, setModalShowing] = useState(false);
  const [noticeModalTitle, setNoticeModalTitle] = useState('');
  const [noticeModalMessage, setNoticeModalMessage] = useState('');
  const handleNoticeModalClose = () => setModalShowing(false);
  const handleNoticeModalShow = () => setModalShowing(true);

  // const [registerFields, handleRegisterFieldChange] = useFormFields({
  //   name: "Reyn Aria",
  //   nik: "12341234",
  //   email: "reyn@virtu.co.id",
  //   site: "2",
  //   jobTitle: "3",
  //   password: "password",
  //   confirmPassword: "password",
  //   invitationCode: "BUMA_LUAR_BIASA"
  // });

  const [registerFields, handleRegisterFieldChange] = useFormFields({
    name: "",
    nik: "",
    email: "",
    site: "",
    jobTitle: "",
    password: "",
    confirmPassword: "",
    invitationCode: ""
  });

  useEffect(() => {
    startup();
    return () => {};
  }, []);
  
  async function startup() {
    setLoading(true);
    
    const response = await axios({
      method: 'get',
      url: CONSTANTS.API_PATH + '/master_data',
      params: {
        in_array: true
      }
    });
  
    // console.log(JSON.stringify(response.data));
    setMasterData(response.data);
    setLoading(false);
  }

  function onRegisterFormChange(e) {
    if (e.target.id==='password') {

      if (e.target.value.length<8) e.target.setCustomValidity('Too short');
      // else if (!/\d/.test(e.target.value)) e.target.setCustomValidity('No number');
      // else if (!/[~`!#$%^&*@+=\-()_[\]\\';,/{}|\\":<>?.]/.test(e.target.value)) e.target.setCustomValidity('No special char');
      // else if (!/[a-z]/.test(e.target.value)) e.target.setCustomValidity('No lowercase');
      // else if (!/[A-Z]/.test(e.target.value)) e.target.setCustomValidity('No uppercase');
      else e.target.setCustomValidity('');
      
      if (confirmPasswordForm.current.value!==e.target.value) confirmPasswordForm.current.setCustomValidity('Password does not match');
      else confirmPasswordForm.current.setCustomValidity('');
    }
    if (e.target.id==='confirmPassword') {
      if (!passwordForm.current.checkValidity()) e.target.setCustomValidity('Password error');
      else if (passwordForm.current.value!==e.target.value) e.target.setCustomValidity('Password does not match');
      else e.target.setCustomValidity('');
    }
    handleRegisterFieldChange(e);
  }

  async function submitRegister(e) {
    e.preventDefault()
    e.stopPropagation()

    // console.log(JSON.stringify(registerFields));

    const form = e.currentTarget
    setLoginValidated(true);
    if (form.checkValidity() === false) {
      return;
    }

    setLoading(true);

    try {
      const newUser = await Auth.signUp({
        username: registerFields.email,
        password: registerFields.password,
        attributes: {
          name: registerFields.name,
          gender: registerFields.gender,
          preferred_username: registerFields.nik,
          'custom:job_title': registerFields.jobTitle,
          'custom:site': registerFields.site,
          'custom:invitation_code': registerFields.invitationCode
        }
      });
      setTemporaryUser(newUser);
    } catch (e) {
      if (e.code === 'UsernameExistsException')
      {
        setNoticeModalTitle('Oops!');
        setNoticeModalMessage("This email has already been registered. Please login instead.");
        handleNoticeModalShow();
      }
      else 
      {
        if (e.message.includes("PreSignUp failed with error")) {
          showNotification(0, e.message.replaceAll("PreSignUp failed with error ", ""));
        } else {
          showNotification(0, e.message);
        }
      }
    }

    setLoading(false);
  }

  function renderLoginForm() {
    return (
      <CForm
        className="loginForm needs-validation d-grid"
        noValidate
        validated={loginValidated}
        onSubmit={submitRegister}
      >
        <CFormFloating>
          <CFormInput
            className="mb-2"
            type="email"
            id="email"
            placeholder="e.g. mail@company.com"
            value={registerFields.email}
            onChange={onRegisterFormChange}
            pattern="[a-z0-9._%+-]+@([a-z0-9.-]+\.[a-z]{2,})$"
            disabled={isLoading}
            required />
          <CFormLabel htmlFor="email">Email</CFormLabel>
        </CFormFloating>
        <CFormFloating>
          <CFormInput
            className="mb-2"
            type="name"
            id="name"
            placeholder="e.g. Joko Taro"
            value={registerFields.name}
            onChange={onRegisterFormChange}
            disabled={isLoading}
            required />
          <CFormLabel htmlFor="name">Full Name</CFormLabel>
        </CFormFloating>
        <CFormFloating>
          <CFormInput
            className="mb-2"
            type="name"
            id="nik"
            placeholder="e.g. 12345678"
            value={registerFields.nik}
            onChange={onRegisterFormChange}
            pattern="^[0-9]{8,20}$"
            minLength={8}
            disabled={isLoading}
            required />
          <CFormLabel htmlFor="nik">NIK</CFormLabel>
        </CFormFloating>
        {/* <CFormFloating>
          <CFormSelect
            id="gender"
            className="mb-2"
            aria-label="Gender"
            onChange={onRegisterFormChange}
            required >
            <option value="">-</option>
            <option value="1">Male</option>
            <option value="2">Female</option>
          </CFormSelect>
          <CFormLabel htmlFor="gender">Gender</CFormLabel>
        </CFormFloating> */}
        <CFormFloating>
          <CFormSelect
            id="jobTitle"
            className="mb-2"
            aria-label="Job Title"
            onChange={onRegisterFormChange}
            defaultValue={registerFields.jobTitle}
            disabled={isLoading}
            required >
            <option value="">-</option>
            {masterData &&
              masterData.job_titles.map(item => (
                <option value={item.id} key={item.id}>{item.name}</option>
              ))
            }
          </CFormSelect>
          <CFormLabel htmlFor="jobTitle">Job Title</CFormLabel>
        </CFormFloating>
        <CFormFloating>
          <CFormSelect
            id="site"
            className="mb-4"
            aria-label="Site"
            onChange={onRegisterFormChange}
            defaultValue={registerFields.site}
            disabled={isLoading}
            required >
            <option value="">-</option>
            {masterData &&
              masterData.sites.map(item => (
                <option value={item.id} key={item.id}>{item.name}</option>
              ))
            }
          </CFormSelect>
          <CFormLabel htmlFor="site">Site</CFormLabel>
        </CFormFloating>

        <CFormFloating>
          <CFormInput
            className="mb-2"
            type="password"
            id="password"
            placeholder="********"
            ref={passwordForm}
            value={registerFields.password}
            onChange={onRegisterFormChange}
            minLength={8}
            disabled={isLoading}
            required />
          <CFormLabel htmlFor="password">Password</CFormLabel>
        </CFormFloating>
        <CFormFloating>
          <CFormInput
            className="mb-4"
            type="password"
            id="confirmPassword"
            placeholder="********"
            ref={confirmPasswordForm}
            value={registerFields.confirmPassword}
            onChange={onRegisterFormChange}
            minLength={8}
            disabled={isLoading}
            required />
          <CFormLabel htmlFor="confirmPassword">Confirm Password</CFormLabel>
        </CFormFloating>
        <CFormFloating>
          <CFormInput
            className="mb-2"
            type="text"
            id="invitationCode"
            placeholder="********"
            value={registerFields.invitationCode}
            onChange={onRegisterFormChange}
            minLength={1}
            disabled={isLoading}
            autoComplete="off"
            required />
          <CFormLabel htmlFor="invitationCode">Invitation Code</CFormLabel>
        </CFormFloating>
        <LoaderButton
          type="submit"
          bssize="large"
          isLoading={isLoading}
        >
          Register
        </LoaderButton>
        <hr />
        <p className="text-center">Already have an account?</p>
        <div className="text-center">
          <Link className="small" to="/">Login</Link>
        </div>
      </CForm>
    );
  }


  function renderRegisterSuccessMessage() {
    return (
      <div>
        <h2 className="text-center mb-5">Registration Successful</h2>
        <h5 className="text-center mb-4">
          Please check your email inbox at<br/>
          <u>{registerFields.email}</u><br/>
          and click on the confirmation link.
        </h5>
        <p className="text-center">(Please also check your SPAM folder if you haven't received it in a few minutes)</p>
      </div>
    );
  }

  return (

    <div className="min-vh-100 bg-gradient-primary">
      <div className="container">
        <div className="row g-0 justify-content-center">
          <div className="col-12">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">

                <div className="row g-0">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                  <div className="col-lg-6">
                    <div className="p-3 p-md-5">
                      <div className="text-center">
                        <Image className="loginLogo" src="img/buma_logo.png" fluid/>
                        <h1 className="h4 text-gray-900 mb-4">VR Training Portal</h1>
                      </div>
                      
                      {temporaryUser ? renderRegisterSuccessMessage() : renderLoginForm()}

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>

      <Modal
        show={isModalShowing}
        onHide={handleNoticeModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>{noticeModalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{noticeModalMessage}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleNoticeModalClose}>Dismiss</Button>
          <Button variant="primary"
            onClick={(e) => {
              e.preventDefault();
              history.push("/");
            }}>
            Go to Login Page
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Register;
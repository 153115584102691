import React, { useEffect, useState, useRef } from "react";
import { Auth } from "aws-amplify";
import { useFormFields } from "../libs/hooksLib";
import { CForm, CFormFloating, CFormInput, CFormLabel } from '@coreui/react';
import { useAppContext } from "../libs/contextLib";
import LoaderButton from "../components/LoaderButton";

const ChangePassword = (props) => {
  const { showNotification } = useAppContext();

  const oldPasswordForm = useRef(null);
  const newPasswordForm = useRef(null);
  const confirmPasswordForm = useRef(null);

  const [formValidated, setFormValidated] = useState(false);

  const [fields, handleFieldChange] = useFormFields({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    return () => {};
  }, []);

  function onFormChange(e) {
    if (e.target.id==='oldPassword') {

      if (e.target.value.length<8) e.target.setCustomValidity('Too short');
      // else if (!/\d/.test(e.target.value)) e.target.setCustomValidity('No number');
      // else if (!/[~`!#$%^&*@+=\-()_[\]\\';,/{}|\\":<>?.]/.test(e.target.value)) e.target.setCustomValidity('No special char');
      // else if (!/[a-z]/.test(e.target.value)) e.target.setCustomValidity('No lowercase');
      // else if (!/[A-Z]/.test(e.target.value)) e.target.setCustomValidity('No uppercase');
      else e.target.setCustomValidity('');
    }
    if (e.target.id==='newPassword') {

      if (e.target.value.length<8) e.target.setCustomValidity('Too short');
      // else if (!/\d/.test(e.target.value)) e.target.setCustomValidity('No number');
      // else if (!/[~`!#$%^&*@+=\-()_[\]\\';,/{}|\\":<>?.]/.test(e.target.value)) e.target.setCustomValidity('No special char');
      // else if (!/[a-z]/.test(e.target.value)) e.target.setCustomValidity('No lowercase');
      // else if (!/[A-Z]/.test(e.target.value)) e.target.setCustomValidity('No uppercase');
      else e.target.setCustomValidity('');
      
      if (confirmPasswordForm.current.value!==e.target.value) confirmPasswordForm.current.setCustomValidity('Password does not match');
      else confirmPasswordForm.current.setCustomValidity('');
    }
    if (e.target.id==='confirmPassword') {
      if (!newPasswordForm.current.checkValidity()) e.target.setCustomValidity('Password error');
      else if (newPasswordForm.current.value!==e.target.value) e.target.setCustomValidity('Password does not match');
      else e.target.setCustomValidity('');
    }
    handleFieldChange(e);
  }

  async function submit(e) {
    e.preventDefault()
    e.stopPropagation()

    const form = e.currentTarget
    setFormValidated(true);
    if (form.checkValidity() === false) {
      return;
    }

    setLoading(true);
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        fields.oldPassword,
        fields.newPassword
      );
      handleFieldChange({target: {
        id: "UPDATE_ALL",
        value: {
          oldPassword: "",
          newPassword: "",
          confirmPassword: ""
        }
      }});
      setFormValidated(false);
      showNotification(1, "Password updated successfully!");
    } catch (error) {
      showNotification(0, error.message);
    }
    setLoading(false);
  }



  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Change Password</h1>
      </div>

      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 col-12">
              <CForm
                className="needs-validation d-grid"
                noValidate
                validated={formValidated}
                onSubmit={submit}
              >
                <CFormFloating>
                  <CFormInput
                    className="mb-5"
                    type="password"
                    id="oldPassword"
                    ref={oldPasswordForm}
                    placeholder="********"
                    value={fields.oldPassword}
                    onChange={onFormChange}
                    disabled={isLoading}
                    autoFocus
                    required />
                  <CFormLabel htmlFor="oldPassword">Old Password</CFormLabel>
                </CFormFloating>
                <CFormFloating>
                  <CFormInput
                    className="mb-2"
                    type="password"
                    id="newPassword"
                    ref={newPasswordForm}
                    placeholder="********"
                    value={fields.newPassword}
                    onChange={onFormChange}
                    disabled={isLoading}
                    required />
                  <CFormLabel htmlFor="newPassword">New Password</CFormLabel>
                </CFormFloating>
                <CFormFloating>
                  <CFormInput
                    className="mb-5"
                    type="password"
                    id="confirmPassword"
                    ref={confirmPasswordForm}
                    placeholder="********"
                    value={fields.confirmPassword}
                    onChange={onFormChange}
                    disabled={isLoading}
                    required />
                  <CFormLabel htmlFor="confirmPassword">Re-Type New Password</CFormLabel>
                </CFormFloating>
                <LoaderButton
                  type="submit"
                  bssize="large"
                  isLoading={isLoading}
                >
                  Update Password
                </LoaderButton>
              </CForm>
            </div>
          </div>
        </div>
      </div>

    </div>

  )
}

export default ChangePassword;
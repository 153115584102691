import React from "react";
import { Route, Switch } from "react-router-dom";

import UnauthenticatedRoute from "./UnauthenticatedRoute";
import AuthenticatedRoute from "./AuthenticatedRoute";
// import AuthenticatedStandardAdminRoute from "./AuthenticatedStandardAdminRoute";
// import AuthenticatedSuperAdminRoute from "./AuthenticatedSuperAdminRoute";

import Login from "./screens/Login";
import ResetPassword from "./screens/ResetPassword";
import Confirm from "./screens/Confirm";
import Register from "./screens/Register";

import Dashboard from "./screens/Dashboard";
import BatchRegistration from "./screens/BatchRegistration";
import GenerateReport from "./screens/GenerateReport";
import InvitationCode from "./screens/InvitationCode";
import Admins from "./screens/Admins";
import Staffs from "./screens/Staffs";
import StaffProfile from "./screens/StaffProfile";
import SearchStaffs from "./screens/SearchStaffs";
import StaffTrainingResults from "./screens/StaffTrainingResults";
import StaffTrainingResult from "./screens/StaffTrainingResult";
import TrainingResults from "./screens/TrainingResults";
import TrainingResult from "./screens/TrainingResult";
import JobTitles from "./screens/JobTitles";
import Sites from "./screens/Sites";
import LaunchTraining from "./screens/LaunchTraining";
import EditProfile from "./screens/EditProfile";
import ChangeEmail from "./screens/ChangeEmail";
import ChangePassword from "./screens/ChangePassword";

import NotFound from "./screens/NotFound";

export default function Routes({ handleUserDidUpdateProfile, ...props }) {
  return (
    <Switch>
      <UnauthenticatedRoute exact path="/">
        <Login />
      </UnauthenticatedRoute>

      <UnauthenticatedRoute exact path="/reset-password">
        <ResetPassword />
      </UnauthenticatedRoute>

      <UnauthenticatedRoute exact path="/register">
        <Register />
      </UnauthenticatedRoute>

      <UnauthenticatedRoute exact path="/confirm">
        <Confirm />
      </UnauthenticatedRoute>


      <AuthenticatedRoute exact path="/dashboard">
        <Dashboard />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/batch-registration">
        <BatchRegistration />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/dashboard/generate-report">
        <GenerateReport />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/invitation-code">
        <InvitationCode />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/admins">
        <Admins />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/staffs">
        <Staffs />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/staffs/search">
        <SearchStaffs />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/staffs/:userSub">
        <StaffProfile />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/staff-training-results">
        <StaffTrainingResults />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/staff-training-results/:userSub/:trainingSub">
        <StaffTrainingResult />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/training-results">
        <TrainingResults />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/training-results/:sub">
        <TrainingResult />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/job-titles">
        <JobTitles />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/sites">
        <Sites />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/launch-training/:target?/:mode?/:field?">
        <LaunchTraining />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/edit-profile">
        <EditProfile handleUserDidUpdateProfile={handleUserDidUpdateProfile} />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/change-email">
        <ChangeEmail />
      </AuthenticatedRoute>

      <AuthenticatedRoute exact path="/change-password">
        <ChangePassword />
      </AuthenticatedRoute>


      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
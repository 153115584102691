import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner, faTag, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as CONSTANTS from "../constants";

const TrainingResult = (props) => {
  let { sub } = useParams();

  const [isLoading, setLoading] = useState(true);
  const [result, setResult] = useState(null);
  const [module, setModule] = useState(null);

  useEffect(() => {
    startup();
    return () => {};
  }, []);
  
  async function startup() {
    setLoading(true);

    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();
    const response = await axios({
      method: 'get',
      url: CONSTANTS.API_PATH + '/training_result',
      params: {
        sub: sub
      },
      headers: { 
        Authorization: "Bearer " + jwt
      }
    });
  
    // console.log(JSON.stringify(response.data));
    setResult(response.data);
    setModule(CONSTANTS.TRAINING_MODULES[response.data.module.toString()]);
    setLoading(false);
  }

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Training Result</h1>
      </div>

      <div className="card shadow mb-4">
        <div className="card-body">
          {isLoading ?
            <>
              <div style={{height: 500}}>
                <FontAwesomeIcon className="text-primary initialSpinner" icon={faSpinner} size="3x" spin/>
              </div>
            </>
          :
            <div>

              <div className="row justify-content-md-center">
                  <div className="col-md-auto">
                      <div className={`card ${result.percentage<100 ? 'bg-danger' : 'bg-success'} text-white shadow`}>
                          <div className="card-body">
                              <div className="text-center h1 font-weight-bold mb-0">{result.percentage}%</div>
                          </div>
                      </div>
                  </div>
              </div>

              <br/>

                      <div className="row">
                          <div className="col-lg-12">
                              <div className="panel panel-default">
                                  <div className="panel-heading">
                                      <p><b>Training Date:</b> {moment(result.timestamp*1000).tz("UTC").add(7, 'hours').format("DD/MM/YYYY HH:mm:ss")}<br/>
                                          <b>Duration:</b> {result.duration}<br/>
                                          <b>Mode:</b> {CONSTANTS.TRAINING_MODES[result.mode.toString()].name}</p>
                                      {/* <h4>{CONSTANTS.TRAINING_MODULES[result.module.toString()].name}</h4> */}
                                  </div>
                                  <div className="panel-body">
                                      <div className="table-responsive">
                                          <table className="table">
                                              <thead>
                                                  <tr>
                                                      <th>#</th>
                                                      <th>Activity</th>
                                                      <th>Task</th>
                                                      <th>Safety</th>
                                                      <th>Production</th>
                                                      <th>Maintenance</th>
                                                      <th>Frequency</th>
                                                      <th>Severity</th>
                                                      <th>Points</th>
                                                      <th>Pass</th>
                                                  </tr>
                                              </thead>
                                              <tbody>

                                                {/* {CONSTANTS.TRAINING_MODULES[result.module.toString()].items.map((item, index) => ( */}
                                                {result.result.map((item, index) => (
                                                  <tr key={index}>
                                                      <td>{index + 1}</td>
                                                      <td>{module.items[item.itemId.toString()].activity}</td>
                                                      <td>{module.items[item.itemId.toString()].task}
                                                      {item.info &&
                                                        <>
                                                          <br /><br />
                                                          <span className="text-danger">{item.info}</span>
                                                        </>
                                                      }</td>
                                                      <td className="text-center">{module.items[item.itemId.toString()].category_safety && <FontAwesomeIcon icon={faTag} />}</td>
                                                      <td className="text-center">{module.items[item.itemId.toString()].category_production && <FontAwesomeIcon icon={faTag} />}</td>
                                                      <td className="text-center">{module.items[item.itemId.toString()].category_maintenance && <FontAwesomeIcon icon={faTag} />}</td>
                                                      <td className="text-center">{module.items[item.itemId.toString()].point_frequency}</td>
                                                      <td className="text-center">{module.items[item.itemId.toString()].point_severity}</td>
                                                      <td className="text-center">{module.items[item.itemId.toString()].point}</td>
                                                      <td className={`text-center ${item.pass ? "text-success" : "text-danger"}`}>{item.pass ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</td>
                                                  </tr>
                                                ))
                                                }
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                          </div>
                      </div>
                  </div>

            </div>
          }
        </div>
      </div>
    </div>

  )
}

export default TrainingResult;
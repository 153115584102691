import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useFormFields } from "../libs/hooksLib";
import { useAppContext } from "../libs/contextLib";
import LoaderButton from "../components/LoaderButton";
import * as CONSTANTS from "../constants";

const Sites = (props) => {
  const { showNotification } = useAppContext();

  const [isLoading, setLoading] = useState(true);
  const [isUpdating, setUpdating] = useState(false);
  const [sites, setSites] = useState([]);

  const [isAddingNewSite, setAddingNewSite] = useState(false);
  const [editedIndex, setEditedIndex] = useState(-1);
  const [editFields, handleEditFieldChange] = useFormFields({
    content: ""
  });

  useEffect(() => {
    startup();
  }, []);

  async function startup() {
    setLoading(true);
    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();
    const response = await axios({
      method: 'get',
      url: CONSTANTS.API_PATH + '/sites',
      headers: {
        Authorization: "Bearer " + jwt
      }
    });

    // console.log(JSON.stringify(response.data));
    setSites(response.data.sites);
    setLoading(false);
  }

  function edit(index) {
    setEditedIndex(index);
    setAddingNewSite(false);
    handleEditFieldChange({
      target: {
        id: "UPDATE_ALL",
        value: {
          content: sites[index].name
        }
      }
    });
  }

  function cancel() {
    setEditedIndex(-1);
    setAddingNewSite(false);
  }

  function addSite() {
    setEditedIndex(-1);
    setAddingNewSite(true);
  }

  async function save(index) {
    setUpdating(true);
    // console.log(sites[index].id + " - " + editFields.content);

    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();

    const updateResponse = await axios({
      method: 'post',
      url: CONSTANTS.API_PATH + '/edit_site',
      headers: {
        Authorization: "Bearer " + jwt
      },
      data: {
        id: sites[index].id,
        name: editFields.content
      }
    });
    setSites(updateResponse.data.new_sites);

    setEditedIndex(-1);
    showNotification(1, "Site updated!");

    setUpdating(false);
  }

  async function submitNewSite() {
    if (editFields.content.trim() === "") return;

    setUpdating(true);

    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();

    const updateResponse = await axios({
      method: 'put',
      url: CONSTANTS.API_PATH + '/add_site',
      headers: {
        Authorization: "Bearer " + jwt
      },
      data: {
        name: editFields.content
      }
    });
    setSites(updateResponse.data.new_sites);

    setAddingNewSite(false);
    handleEditFieldChange({
      target: {
        id: "UPDATE_ALL",
        value: {
          content: ""
        }
      }
    });
    showNotification(1, "Site added!");

    setUpdating(false);
  }

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Sites</h1>
      </div>

      <div className="card shadow mb-4">
        <div className="card-body">
          {isLoading ?
            <>
              <div style={{ height: 500 }}>
                <FontAwesomeIcon className="text-primary initialSpinner" icon={faSpinner} size="3x" spin />
              </div>
            </>
            :
            <div className="table-responsive">
              <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th className="fit"></th>
                  </tr>
                </thead>
                <tbody>
                  {sites && sites.map((item, index) => (
                    index === editedIndex ?
                      <tr key={index}>
                        <td className="fit">{item.id}</td>
                        <td>
                          <Form.Group controlId="content" className="mb-2">
                            <Form.Control
                              autoFocus={true}
                              spellCheck="false"
                              disabled={isUpdating}
                              value={editFields.content}
                              onChange={handleEditFieldChange}
                            />
                          </Form.Group>
                          <LoaderButton isLoading={isUpdating} onClick={() => save(index)} variant="primary" className="mr-2">
                            <i className="fas fa-save fa-sm text-gray" /> Save
                          </LoaderButton>
                          <Button disabled={isUpdating} onClick={() => cancel()} variant="danger">
                            <i className="fas fa-times fa-sm text-gray" /> Cancel
                          </Button>
                        </td>
                        <td className="fit">
                        </td>
                      </tr>
                      :
                      <tr key={index}>
                        <td className="fit">{item.id}</td>
                        <td>{item.name}</td>
                        <td className="fit">
                          <Button disabled={isUpdating} onClick={() => edit(index)} variant="warning">
                            <i className="fas fa-edit fa-sm text-gray" /> Edit
                          </Button>
                        </td>
                      </tr>
                  ))}
                </tbody>
              </table>

              {isAddingNewSite ?
                <div className="text-center p-2">
                  <Form.Group controlId="content" className="mb-2">
                    <Form.Control
                      className="text-center"
                      autoFocus={true}
                      spellCheck="false"
                      disabled={isUpdating}
                      value={editFields.content}
                      onChange={handleEditFieldChange}
                    />
                  </Form.Group>
                  <LoaderButton isLoading={isUpdating} onClick={() => submitNewSite()} variant="primary" className="mr-2">
                    <i className="fas fa-save fa-sm text-gray" /> Add Site
                  </LoaderButton>
                  <Button disabled={isUpdating} onClick={() => cancel()} variant="danger">
                    <i className="fas fa-times fa-sm text-gray" /> Cancel
                  </Button>
                </div>
                :
                <div className="text-center">
                  <Button disabled={isUpdating} onClick={() => addSite()} variant="primary" className="mr-2">
                    <i className="fas fa-plus fa-sm text-gray" /> Add New Site
                  </Button>
                </div>
              }
            </div>

          }

        </div>
      </div>

    </div>

  )
}

export default Sites;
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import * as CONSTANTS from "../constants";
import { Link } from 'react-router-dom';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';

const Dashboard = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const [trainingTotalChartData, setTrainingTotalChartData] = useState(null);
  const [passFailChartData, setPassFailChartData] = useState(null);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  function numberFormat(number, decimals, dec_point, thousands_sep) {
    // *     example: number_format(1234.56, 2, ',', ' ');
    // *     return: '1 234,56'
    number = (number + '').replace(',', '').replace(' ', '');
    var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
      dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
      s = '',
      toFixedFix = function(n, prec) {
        var k = Math.pow(10, prec);
        return '' + Math.round(n * k) / k;
      };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
  }

  const trainingTotalChartOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 25,
        bottom: 0
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          maxTicksLimit: 12
        }
      },
      y: {
        ticks: {
          maxTicksLimit: 5,
          padding: 10,
          callback: function(value, index, values) {
            return numberFormat(value);
          }
        },
        grid: {
          color: "rgb(234, 236, 244)",
          zeroLineColor: "rgb(234, 236, 244)",
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2]
        }
      },
    },
    plugins: {
      legend: false,
      title: {
        display: false
      },
      tooltip: {
        backgroundColor: "rgb(255,255,255)",
        bodyColor: "#858796",
        titleMarginBottom: 10,
        titleColor: '#6e707e',
        titleFont: { size: 14 },
        borderColor: '#dddfeb',
        borderWidth: 1,
        padding: 15,
        displayColors: false,
        intersect: false,
        mode: 'index',
        caretPadding: 10,
        callbacks: {
          label: function(context) {
            var datasetLabel = context.dataset.label || '';
            return datasetLabel + ': ' + numberFormat(context.raw);
          }
        }
      }
    },
  };

  const passFailChartOptions = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 25,
        bottom: 0
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          maxTicksLimit: 12
        },
        maxBarThickness: 25,
      },
      y: {
        ticks: {
          // min: 0,
          // max: trainingChartMax,
          maxTicksLimit: 5,
          padding: 10,
          callback: function(value, index, values) {
            return numberFormat(value);
          }
        },
        grid: {
          color: "rgb(234, 236, 244)",
          zeroLineColor: "rgb(234, 236, 244)",
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2]
        }
      },
    },
    plugins: {
      legend: false,
      title: {
        display: false
      },
      tooltip: {
        titleMarginBottom: 10,
        titleColor: '#6e707e',
        titleFont: { size: 14 },
        backgroundColor: "rgb(255,255,255)",
        bodyColor: "#858796",
        borderColor: '#dddfeb',
        borderWidth: 1,
        padding: 15,
        displayColors: false,
        caretPadding: 10,
        callbacks: {
          label: function(context) {
            var datasetLabel = context.dataset.label || '';
            return datasetLabel + ': ' + numberFormat(context.raw);
          }
        }
      },
    },
  };

  useEffect(() => {
    startup();
    return () => {};
  }, []);
  
  async function startup() {
    setLoading(true);
    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();
    const response = await axios({
      method: 'get',
      url: CONSTANTS.API_PATH + '/dashboard_data',
      headers: { 
        Authorization: "Bearer " + jwt
      }
    });
  
    // console.log(JSON.stringify(response.data));
    setDashboardData(response.data);

    let labels = response.data.chart.map(monthData => moment.monthsShort(parseInt(monthData.month) - 1));
    let trainingTotalData = response.data.chart.map(monthData => monthData.data.total);
    let passData = response.data.chart.map(monthData => monthData.data.pass);
    let failData = response.data.chart.map(monthData => monthData.data.fail);

    // let labels = ["Sep", "Oct", "Nov"];
    // let trainingTotalData = [200, 158, 72];
    // let passData = [200, 158, 72];
    // let failData = [137, 99, 126];
    setTrainingTotalChartData({
      labels,
      datasets: [
        {
          label: 'Total Trainings',
          // fill: {
          //   target: "origin",
          //   above: 'rgb(255, 0, 0)',
          //   below: 'rgb(0, 0, 255)' 
          // },
          data: trainingTotalData,
          lineTension: 0,
          backgroundColor: "rgba(78, 115, 223, 0.05)",
          borderColor: "rgba(78, 115, 223, 1)",
          pointRadius: 3,
          pointBackgroundColor: "rgba(78, 115, 223, 1)",
          pointBorderColor: "rgba(78, 115, 223, 1)",
          pointHoverRadius: 3,
          pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
          pointHoverBorderColor: "rgba(78, 115, 223, 1)",
          pointHitRadius: 10,
          pointBorderWidth: 2
        }
      ],
    });

    setPassFailChartData({
      labels,
      datasets: [
        {
          label: "Pass",
          backgroundColor: "#1cc88a",
          hoverBackgroundColor: "#1cc88a",
          borderColor: "#1cc88a",
          data: passData,
        }, {
          label: "Fail",
          backgroundColor: "#e74a3b",
          hoverBackgroundColor: "#e74a3b",
          borderColor: "#e74a3b",
          data: failData,
        }
      ],
    });


    setLoading(false);
  }

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
        <Link to="/dashboard/generate-report" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i className="fas fa-download fa-sm text-white-50"></i> Generate Report</Link>
      </div>

      {isLoading ?
        <>
          <div style={{height: 500}}>
            <FontAwesomeIcon className="text-primary initialSpinner" icon={faSpinner} size="3x" spin/>
          </div>
        </>
      :
        <>

          <div className="row g-4 mb-4">

            <div className="col-xl-3 col-md-6">
              <div className="card border-left-secondary shadow h-100 py-2">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col mr-2">
                      <div className="text-s font-weight-bold text-secondary text-uppercase mb-1">Staffs</div>
                      <div className="h3 mb-0 font-weight-bold text-gray-800">{dashboardData.counter.users}</div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-users fa-3x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="card border-left-info shadow h-100 py-2">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col mr-2">
                      <div className="text-s font-weight-bold text-info text-uppercase mb-1">Trainings</div>
                      <div className="h3 mb-0 font-weight-bold text-gray-800">{dashboardData.counter.trainings}</div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-dumbbell fa-3x text-info"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="card border-left-success shadow h-100 py-2">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col mr-2">
                      <div className="text-s font-weight-bold text-success text-uppercase mb-1">Modules</div>
                      <div className="h3 mb-0 font-weight-bold text-gray-800">{dashboardData.counter.modules}</div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-clipboard-list fa-3x text-success"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="card border-left-warning shadow h-100 py-2">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col mr-2">
                      <div className="text-s font-weight-bold text-warning text-uppercase mb-1">Sites</div>
                      <div className="h3 mb-0 font-weight-bold text-gray-800">{dashboardData.counter.sites}</div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-building fa-3x text-warning"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          {/* <div className="card shadow mb-4">
            <div className="card-body">
              <div className="col-12">
                <p>Welcome to BUMA Portal</p>
              </div>

            </div>
          </div> */}

          <div className="row">

            <div className="col-xl-12 col-lg-12">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">Total Trainings</h6>
                </div>
                <div className="card-body">
                  <div className="chart-area">
                    {trainingTotalChartData &&
                      <Line
                        options={trainingTotalChartOptions}
                        data={trainingTotalChartData} />
                    }
                  </div>
                </div>
              </div>
            </div>

          </div>


          <div className="row">

            <div className="col-xl-12 col-lg-12">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">Pass vs Fail</h6>
                </div>
                <div className="card-body">
                  <div className="chart-area">
                    {passFailChartData &&
                      <Bar
                        options={passFailChartOptions}
                        data={passFailChartData} />
                    }
                  </div>
                </div>
              </div>
            </div>

          </div>
        </>
      }


    </div>

  )
}

export default Dashboard;
import React, { useState, useRef } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { Image, Button, Form, Modal } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import LoaderButton from "../components/LoaderButton";
import { CForm, CFormFloating, CFormInput, CFormLabel } from '@coreui/react';
import { useFormFields } from "../libs/hooksLib";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

export default function ResetPassword() {
  const { showNotification } = useAppContext();

  const passwordForm = useRef(null);
  const confirmPasswordForm = useRef(null);

  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [resetPasswordValidated, setResetPasswordValidated] = useState(false);
  const [updatePasswordValidated, setUpdatePasswordValidated] = useState(false);
  const [passwordValidator, setPasswordValidator] = useState(null);
  
  const [isModalShowing, setModalShowing] = useState(false);
  const [noticeModalTitle, setNoticeModalTitle] = useState('');
  const [noticeModalMessage, setNoticeModalMessage] = useState('');
  const handleNoticeModalClose = () => setModalShowing(false);
  const handleNoticeModalShow = () => setModalShowing(true);

  function validateCodeForm() {
    return fields.email.length > 0;
  }

  // function validateResetForm() {
  //   return (
  //     fields.code.length > 0 &&
  //     fields.password.length > 7 &&
  //     fields.password === fields.confirmPassword
  //   );
  // }

  async function handleResetPasswordClick(event) {
    event.preventDefault()
    event.stopPropagation()

    const form = event.currentTarget
    setResetPasswordValidated(true);
    if (form.checkValidity() === false) {
      return;
    }

    setIsSendingCode(true);

    try {
      await Auth.forgotPassword(fields.email);
      setCodeSent(true);
    } catch (error) {
      setNoticeModalTitle('Oops!');
      setNoticeModalMessage(error.message);
      handleNoticeModalShow();
      setIsSendingCode(false);
    }
  }

  function onFormChange(e) {
    if (e.target.id==='password') {

      setPasswordValidator({
        length: e.target.value.length>=8,
        number: /\d/.test(e.target.value),
        lowercase: /[a-z]/.test(e.target.value),
        uppercase: /[A-Z]/.test(e.target.value),
        special: /[~`!#$%^&*@+=\-()_[\]\\';,/{}|\\":<>?.]/.test(e.target.value)
      })

      if (e.target.value.length<8) e.target.setCustomValidity('Too short');
      // else if (!/\d/.test(e.target.value)) e.target.setCustomValidity('No number');
      // else if (!/[~`!#$%^&*@+=\-()_[\]\\';,/{}|\\":<>?.]/.test(e.target.value)) e.target.setCustomValidity('No special char');
      // else if (!/[a-z]/.test(e.target.value)) e.target.setCustomValidity('No lowercase');
      // else if (!/[A-Z]/.test(e.target.value)) e.target.setCustomValidity('No uppercase');
      else e.target.setCustomValidity('');
      
      if (confirmPasswordForm.current.value!==e.target.value) confirmPasswordForm.current.setCustomValidity('Password does not match');
      else confirmPasswordForm.current.setCustomValidity('');
    }
    if (e.target.id==='confirmPassword') {
      if (!passwordForm.current.checkValidity()) e.target.setCustomValidity('Password error');
      else if (passwordForm.current.value!==e.target.value) e.target.setCustomValidity('Password does not match');
      else e.target.setCustomValidity('');
    }
    handleFieldChange(e);
  }

  async function handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation()

    const form = e.currentTarget
    setUpdatePasswordValidated(true);
    if (form.checkValidity() === false) {
      return;
    }

    setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.password
      );
      setConfirmed(true);
    } catch (error) {
      setNoticeModalTitle('Oops!');
      setNoticeModalMessage(error.message);
      handleNoticeModalShow();
      setIsConfirming(false);
    }
  }

  function renderRequestCodeForm() {
    return (
      <CForm
        className="loginForm needs-validation d-grid"
        noValidate
        validated={resetPasswordValidated}
        onSubmit={handleResetPasswordClick}
      >
        <p className="text-center">Please enter your email address and we'll send you instructions on how to reset your password</p>
        <CFormFloating>
          <CFormInput
            className="mb-2"
            type="email"
            id="email"
            placeholder="mail@company.com"
            value={fields.email}
            pattern="[a-z0-9._%+-]+@([a-z0-9.-]+\.[a-z]{2,})$"
            onChange={handleFieldChange}
            disabled={isConfirming}
            autoFocus
            required />
          <CFormLabel htmlFor="email">Email</CFormLabel>
        </CFormFloating>
        <LoaderButton
          type="submit"
          bsSize="large"
          isLoading={isSendingCode}
        >
          Reset password
        </LoaderButton>
      </CForm>
    );
  }

  function renderConfirmationForm() {
    return (
      <CForm
        className="needs-validation d-grid"
        noValidate
        validated={updatePasswordValidated}
        onSubmit={handleConfirmClick}
      >
        {/* <Form.Group bsSize="large" controlId="code">
          <Form.Label>Verification Code</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            value={fields.code}
            onChange={handleFieldChange}
          />
          <Form.Text>
            Please check your email ({fields.email}) for the verification code.
          </Form.Text>
        </Form.Group> */}
        <CFormFloating>
          <CFormInput
            className="mb-2"
            type="tel"
            id="code"
            placeholder="********"
            value={fields.code}
            onChange={onFormChange}
            disabled={isConfirming}
            autoComplete="off"
            autoFocus
            required />
          <CFormLabel htmlFor="code">Verification Code</CFormLabel>
        </CFormFloating>
        <Form.Text>
          Please check your email ({fields.email}) for the verification code.
        </Form.Text>
        <hr />
        <Form.Text className="text-muted">
          For security purposes, your password must:
          {passwordValidator ?
            <ul className="list-unstyled passwordValidatorList">
              <li className={passwordValidator.length ? 'text-success' : 'text-danger'}><FontAwesomeIcon icon={passwordValidator.length ? faCheck : faTimes}/> be at least 8 characters in length.</li>
              {/* <li className={passwordValidator.lowercase ? 'text-success' : 'text-danger'}><FontAwesomeIcon icon={passwordValidator.lowercase ? faCheck : faTimes}/> contain at least one lowercase character.</li>
              <li className={passwordValidator.uppercase ? 'text-success' : 'text-danger'}><FontAwesomeIcon icon={passwordValidator.uppercase ? faCheck : faTimes}/> contain at least one uppercase character.</li>
              <li className={passwordValidator.number ? 'text-success' : 'text-danger'}><FontAwesomeIcon icon={passwordValidator.number ? faCheck : faTimes}/> contain at least one number.</li>
              <li className={passwordValidator.special ? 'text-success' : 'text-danger'}><FontAwesomeIcon icon={passwordValidator.special ? faCheck : faTimes}/> contain at least one special character.</li> */}
            </ul>
          :
            <ul className="list-unstyled passwordValidatorList">
              <li>&bull; be at least 8 characters in length.</li>
              {/* <li>&bull; contain at least one lowercase character.</li>
              <li>&bull; contain at least one uppercase character.</li>
              <li>&bull; contain at least one number.</li>
              <li>&bull; contain at least one special character.</li> */}
            </ul>
          }
        </Form.Text>
        {/* <Form.Group bsSize="large" controlId="password">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="********"
            value={fields.password}
            onChange={onFormChange}
          />
        </Form.Group> */}
        <CFormFloating>
          <CFormInput
            className="mb-2"
            type="password"
            id="password"
            ref={passwordForm}
            placeholder="********"
            value={fields.password}
            onChange={onFormChange}
            disabled={isConfirming}
            required />
          <CFormLabel htmlFor="password">New Password</CFormLabel>
        </CFormFloating>
        {/* <Form.Group bsSize="large" controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="********"
            value={fields.confirmPassword}
            onChange={onFormChange}
          />
        </Form.Group> */}

        <CFormFloating>
          <CFormInput
            className="mb-2"
            type="password"
            id="confirmPassword"
            ref={confirmPasswordForm}
            placeholder="********"
            value={fields.confirmPassword}
            onChange={onFormChange}
            disabled={isConfirming}
            required />
          <CFormLabel htmlFor="confirmPassword">Re-Type Password</CFormLabel>
        </CFormFloating>
        <LoaderButton
          type="submit"
          bsSize="large"
          isLoading={isConfirming}
          // disabled={!validateResetForm()}
        >
          Confirm
        </LoaderButton>
      </CForm>
    );
  }

  function renderSuccessMessage() {
    return (
      <div className="success text-center">
        <FontAwesomeIcon icon={faCheck} size="3x" />
        <p>Your password has been reset.</p>
        <p>
          <Link to="/">
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    );
  }

  return (

    <div className="min-vh-100 bg-gradient-primary">
      <div className="container">
        <div className="row g-0 justify-content-center">
          <div className="col-12">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">

                <div className="row g-0">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                  <div className="col-lg-6">
                    <div className="p-3 p-md-5">
                      <div className="text-center">
                        <Image className="loginLogo" src="img/buma_logo.png" fluid/>
                        <h1 className="h4 text-gray-900 mb-4">VR Training Portal</h1>
                      </div>

                      {!codeSent
                        ? renderRequestCodeForm()
                        : !confirmed
                        ? renderConfirmationForm()
                        : renderSuccessMessage()}

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <Modal
        show={isModalShowing}
        onHide={handleNoticeModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>{noticeModalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{noticeModalMessage}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleNoticeModalClose}>Dismiss</Button>
          <Button variant="primary"
            onClick={(e) => {
              e.preventDefault();
              window.location.href='/';
            }}>
            Go to Login Page
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
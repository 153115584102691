import React, { useEffect, useState } from 'react';
import { Auth } from "aws-amplify";
import { onError } from "../libs/errorLib";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useHistory } from 'react-router-dom';
import { Image, Button } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";

const Confirm = (props) => {
  const location = useLocation();
  const history = useHistory();
  const search = new URLSearchParams(location.search);

  const username = search.get('username') || '';
  const code = search.get('code') || '';
  const clientId = search.get('clientId') || '';
  const email = search.get('email') || '';

  const [isConfirming, setIsConfirming] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const [isResendingEmail, setIsResendingEmail] = useState(false);
  const [isEmailResent, setIsEmailResent] = useState(false);

  async function handleResendEmail(event) {
    event.preventDefault();

    setIsResendingEmail(true);
  
    try {
      await Auth.resendSignUp(email);
      setIsEmailResent(true);
    } catch (e) {
      // console.log(JSON.stringify(e));
      onError(e);
    }

    setIsResendingEmail(false);
  }

  useEffect(() => {
    const confirmUser = async () => {
      try {
        await Auth.confirmSignUp(username, code);
        setIsConfirming(false);
        setIsConfirmed(true);
      } catch (err) {
        setIsConfirming(false);
        setIsConfirmed(false);
      }
    };
    confirmUser();
  }, [username, code, clientId]);


  function renderConfirming() {
    return (
      <div className="text-center">
        <FontAwesomeIcon icon={faSpinner} spin size="3x" /><br /> Validating registration...
      </div>
    );
  }

  function renderConfirmed() {
    return (
      <div className="text-center">
        <FontAwesomeIcon icon={faCheck} size="3x" /
        ><br />
        Registration confirmed!
        <br />
        <br />
        <Button
          type="submit"
          bssize="large"
          onClick={(e) => {
            e.preventDefault();
            history.push("/");
            }}
        >
          Go to Login Page
        </Button>
      </div>
    );
  }

  function renderInvalidLink() {
    return (
      <div className="text-center">
        <FontAwesomeIcon icon={faTimes} size="3x" />
        <br />
        Invalid link provided, please request a verification link again.
        <br />
        <br />
        <LoaderButton
          variant={isEmailResent ? "primary" : "primary"}
          onClick={handleResendEmail}
          isLoading={isResendingEmail}
          disabled={isEmailResent}
          >
          {isEmailResent ? 'Resent! Please check your inbox' : 'Resend Confirmation Email'}
        </LoaderButton>
      </div>
    );
  }

  return (

    <div className="min-vh-100 bg-gradient-primary">
      <div className="container">
        <div className="row g-0 justify-content-center">
          <div className="col-12">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">

                <div className="row g-0">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                  <div className="col-lg-6">
                    <div className="p-3 p-md-5">
                      <div className="text-center">
                        <Image className="loginLogo" src="img/buma_logo.png" fluid/>
                        <h1 className="h4 text-gray-900 mb-4">VR Training Portal</h1>
                      </div>
                      
                      {isConfirming 
                      ?
                        renderConfirming()
                      : <>
                        {isConfirmed
                          ? 
                            renderConfirmed()
                          : 
                            renderInvalidLink()
                        }
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>

      {/* <Modal
        show={isModalShowing}
        onHide={handleNoticeModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>{noticeModalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{noticeModalMessage}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleNoticeModalClose}>Dismiss</Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  )
}

export default Confirm;
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAppContext } from "./libs/contextLib";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SidebarAdmin from "./components/SidebarAdmin";
import SidebarStaff from "./components/SidebarStaff";

export default function AuthenticatedRoute({ children, ...rest }) {
  const { isAuthenticated, userProfile, userLevel, handleLogoutModalOpen, sidebarToggle } = useAppContext();
  return (

    <>
      {userLevel >= 100 ?
        <SidebarAdmin />
      :
        <SidebarStaff />
      }
      <div id="content-wrapper" className="d-flex flex-column min-vh-100">
        <div id="content min-vh-100">
          <Header
           userName={userProfile && userProfile.name ? userProfile.name : ""}
           userEmail={userProfile && userProfile.email ? userProfile.email : ""}
           handleLogoutModalOpen={handleLogoutModalOpen}
           sidebarToggle={sidebarToggle} />
          <div className="">
            <Route {...rest}>
              {isAuthenticated ? (
                children
              ) : (
                <Redirect to={
                  "/"
                } />
              )}
            </Route>
          </div>
        </div>
        <Footer />
        
      </div>
    </>
    // <Route {...rest}>
    //   {isAuthenticated ? (
    //     children
    //   ) : (
    //     <Redirect to={
    //       "/"
    //     } />
    //   )}
    // </Route>
  );
}
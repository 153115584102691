import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { Image, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useFormFields } from "../libs/hooksLib";
import LoaderButton from "../components/LoaderButton";
import { CForm, CFormFloating, CFormInput, CFormLabel } from '@coreui/react';

const Login = (props) => {
  const { userHasAuthenticated, userHasProfile, userHasLevel, showNotification } = useAppContext();

  const history = useHistory();

  const passwordForm = useRef(null);
  const confirmPasswordForm = useRef(null);

  const [userNeedUpdatePassword, setUserNeedUpdatePassword] = useState(false);
  const [temporaryUser, setTemporaryUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loginValidated, setLoginValidated] = useState(false);
  const [updatePasswordValidated, setUpdatePasswordValidated] = useState(false);
  const [passwordValidator, setPasswordValidator] = useState(null);

  const [isResendingEmail, setIsResendingEmail] = useState(false);
  const [isEmailResent, setIsEmailResent] = useState(false);

  const [isModalShowing, setModalShowing] = useState(false);
  const [noticeModalTitle, setNoticeModalTitle] = useState('');
  const [noticeModalMessage, setNoticeModalMessage] = useState('');
  const handleNoticeModalClose = () => setModalShowing(false);
  const handleNoticeModalShow = () => setModalShowing(true);

  const [loginFields, handleLoginFieldChange] = useFormFields({
    email: "",
    password: ""
  });

  // const [loginFields, handleLoginFieldChange] = useFormFields({
  //   email: "reyn@virtu.co.id",
  //   password: "Password1!"
  // });

  const [updatePasswordFields, handleUpdatePasswordFieldChange] = useFormFields({
    password: "",
    confirmPassword: ""
  });

  // const [updatePasswordFields, handleUpdatePasswordFieldChange] = useFormFields({
  //   password: "Password1!",
  //   confirmPassword: "Password1!"
  // });

  function onUpdatePasswordFormChange(e) {
    if (e.target.id==='password') {

      setPasswordValidator({
        length: e.target.value.length>=8,
        number: /\d/.test(e.target.value),
        lowercase: /[a-z]/.test(e.target.value),
        uppercase: /[A-Z]/.test(e.target.value),
        special: /[~`!#$%^&*@+=\-()_[\]\\';,/{}|\\":<>?.]/.test(e.target.value)
      })

      if (e.target.value.length<8) e.target.setCustomValidity('Too short');
      else if (!/\d/.test(e.target.value)) e.target.setCustomValidity('No number');
      else if (!/[~`!#$%^&*@+=\-()_[\]\\';,/{}|\\":<>?.]/.test(e.target.value)) e.target.setCustomValidity('No special char');
      else if (!/[a-z]/.test(e.target.value)) e.target.setCustomValidity('No lowercase');
      else if (!/[A-Z]/.test(e.target.value)) e.target.setCustomValidity('No uppercase');
      else e.target.setCustomValidity('');
      
      if (confirmPasswordForm.current.value!==e.target.value) confirmPasswordForm.current.setCustomValidity('Password does not match');
      else confirmPasswordForm.current.setCustomValidity('');
    }
    if (e.target.id==='confirmPassword') {
      if (!passwordForm.current.checkValidity()) e.target.setCustomValidity('Password error');
      else if (passwordForm.current.value!==e.target.value) e.target.setCustomValidity('Password does not match');
      else e.target.setCustomValidity('');
    }
    handleUpdatePasswordFieldChange(e);
  }

  async function submitLogin(e) {
    e.preventDefault()
    e.stopPropagation()

    const form = e.currentTarget
    setLoginValidated(true);
    if (form.checkValidity() === false) {
      return;
    }

    setIsLoading(true);

    try {
      let user = await Auth.signIn(loginFields.email, loginFields.password);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setTemporaryUser(user);
        setUserNeedUpdatePassword(true);
        setIsLoading(false);
      } else {
        let currentUserInfo = await Auth.currentUserInfo()
        // console.log(JSON.stringify(currentUserInfo));
        // console.log(currentUserInfo.attributes);
        setIsLoading(false);
        userHasLevel(parseInt(currentUserInfo.attributes["custom:level"]));
        userHasProfile(currentUserInfo.attributes);
        userHasAuthenticated(true);
        // console.log(parseInt(currentUserInfo.attributes["custom:level"]));
        if (parseInt(currentUserInfo.attributes["custom:level"])>=100) {
          history.push("/dashboard");
        } else {
          history.push("/launch-training");
        }
        return;
        
      }
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        let msg = `You should have received a verification email in your inbox (${loginFields.email}). If you have not received it, you may request to resend the verification email.`;

        setIsEmailResent(false);
        setNoticeModalTitle('Please confirm your email');
        setNoticeModalMessage(msg);
        handleNoticeModalShow();
      } else {
        console.log(e.message);
        // console.log(JSON.stringify(e));
        showNotification(0, e.message);
      }
    }

    setIsLoading(false);
  }

  async function handleResendEmail(event) {
    event.preventDefault();

    setIsResendingEmail(true);
  
    try {
      await Auth.resendSignUp(loginFields.email);
      setIsEmailResent(true);
    } catch (e) {
      // console.log(JSON.stringify(e));
      showNotification(0, e.message);
    }

    setIsResendingEmail(false);
  }

  async function submitUpdatePassword(e) {
    e.preventDefault()
    e.stopPropagation()

    const form = e.currentTarget
    setUpdatePasswordValidated(true);
    if (form.checkValidity() === false) {
      return;
    }

    setIsLoading(true);

    try {
      await Auth.completeNewPassword(temporaryUser, updatePasswordFields.password);
      let currentUserInfo = await Auth.currentUserInfo()
      // console.log(JSON.stringify(currentUserInfo));
      // console.log(currentUserInfo.attributes);
      userHasProfile(currentUserInfo.attributes);
      userHasAuthenticated(true);
      userHasLevel(parseInt(currentUserInfo.attributes["custom:level"]));
      setIsLoading(false);
      history.push("/beranda");
    } catch(e) {
      showNotification(0, e.message);
    }
  }


  function renderLoginForm() {
    return (
      <CForm
        className="loginForm needs-validation d-grid"
        noValidate
        validated={loginValidated}
        onSubmit={submitLogin}
      >
        <CFormFloating>
          <CFormInput
            className="mb-2"
            type="email"
            id="email"
            placeholder="e.g. mail@company.com"
            value={loginFields.email}
            onChange={handleLoginFieldChange}
            pattern="[a-z0-9._%+-]+@([a-z0-9.-]+\.[a-z]{2,})$"
            disabled={isLoading}
            autoFocus
            required />
          <CFormLabel htmlFor="email">Email</CFormLabel>
        </CFormFloating>
        <CFormFloating>
          <CFormInput
            className="mb-2"
            type="password"
            id="password"
            placeholder="********"
            value={loginFields.password}
            onChange={handleLoginFieldChange}
            minLength={8}
            disabled={isLoading}
            required />
          <CFormLabel htmlFor="password">Password</CFormLabel>
        </CFormFloating>
        <LoaderButton
          type="submit"
          bssize="large"
          isLoading={isLoading}
        >
          Login
        </LoaderButton>
        <hr />
        <p className="text-center">Forgot your password?</p>
        <div className="text-center">
          <Link className="small" to="/reset-password">Reset Password</Link>
        </div>
        <hr />
        <p className="text-center">Don't have an account yet?</p>
        <div className="text-center">
          <Link className="small" to="/register">Create an Account</Link>
        </div>
      </CForm>
    );
  }

  function renderUpdatePasswordForm() {
    return (
      <CForm
        className="loginForm needs-validation d-grid"
        noValidate
        validated={updatePasswordValidated}
        onSubmit={submitUpdatePassword}
      >
        <p>You need to set a new password before continuing to dashboard.</p>

        For security purposes, your password must:
        {passwordValidator ?
          <ul className="list-unstyled passwordValidatorList">
            <li className={passwordValidator.length ? 'text-success' : 'text-danger'}><FontAwesomeIcon icon={passwordValidator.length ? faCheck : faTimes}/> be at least 8 characters in length.</li>
            <li className={passwordValidator.lowercase ? 'text-success' : 'text-danger'}><FontAwesomeIcon icon={passwordValidator.lowercase ? faCheck : faTimes}/> contain at least one lowercase character.</li>
            <li className={passwordValidator.uppercase ? 'text-success' : 'text-danger'}><FontAwesomeIcon icon={passwordValidator.uppercase ? faCheck : faTimes}/> contain at least one uppercase character.</li>
            <li className={passwordValidator.number ? 'text-success' : 'text-danger'}><FontAwesomeIcon icon={passwordValidator.number ? faCheck : faTimes}/> contain at least one number.</li>
            <li className={passwordValidator.special ? 'text-success' : 'text-danger'}><FontAwesomeIcon icon={passwordValidator.special ? faCheck : faTimes}/> contain at least one special character.</li>
          </ul>
        :
          <ul className="list-unstyled passwordValidatorList">
            <li>&bull; be at least 8 characters in length.</li>
            <li>&bull; contain at least one lowercase character.</li>
            <li>&bull; contain at least one uppercase character.</li>
            <li>&bull; contain at least one number.</li>
            <li>&bull; contain at least one special character.</li>
          </ul>
        }
        <CFormFloating>
          <CFormInput
            className="mb-2"
            type="password"
            id="password"
            ref={passwordForm}
            placeholder="********"
            value={updatePasswordFields.password}
            onChange={onUpdatePasswordFormChange}
            disabled={isLoading}
            required />
          <CFormLabel htmlFor="password">New Password</CFormLabel>
        </CFormFloating>
        <CFormFloating>
          <CFormInput
            className="mb-2"
            type="password"
            id="confirmPassword"
            ref={confirmPasswordForm}
            placeholder="********"
            value={updatePasswordFields.confirmPassword}
            onChange={onUpdatePasswordFormChange}
            disabled={isLoading}
            required />
          <CFormLabel htmlFor="confirmPassword">Re-Type Password</CFormLabel>
        </CFormFloating>
        <LoaderButton
          type="submit"
          bssize="large"
          isLoading={isLoading}
        >
          Update Password
        </LoaderButton>
      </CForm>
    );
  }

  return (

    <div className="min-vh-100 bg-gradient-primary">
      <div className="container">
        <div className="row g-0 justify-content-center">
          <div className="col-12">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">

                <div className="row g-0">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                  <div className="col-lg-6">
                    <div className="p-3 p-md-5">
                      <div className="text-center">
                        <Image className="loginLogo" src="img/buma_logo.png" fluid/>
                        <h1 className="h4 text-gray-900 mb-4">VR Training Portal</h1>
                      </div>
                      
                      {userNeedUpdatePassword ? renderUpdatePasswordForm() : renderLoginForm()}

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>

      <Modal
        show={isModalShowing}
        onHide={handleNoticeModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>{noticeModalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{noticeModalMessage}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleNoticeModalClose}>Dismiss</Button>
          <LoaderButton
            variant={isEmailResent ? "primary" : "primary"}
            onClick={handleResendEmail}
            isLoading={isResendingEmail}
            disabled={isEmailResent}
            >
            {isEmailResent ? 'Resent! Please check your inbox' : 'Resend Email'}
          </LoaderButton>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Login;
import React, { useEffect, useState, useRef } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useFormFields } from "../libs/hooksLib";
import { CForm, CFormFloating, CFormInput, CFormLabel } from '@coreui/react';
import { useAppContext } from "../libs/contextLib";
import LoaderButton from "../components/LoaderButton";
import * as CONSTANTS from "../constants";

const ChangeEmail = (props) => {
  const { showNotification } = useAppContext();

  const [formValidated, setFormValidated] = useState(false);

  const [fields, handleFieldChange] = useFormFields({
    code: ""
  });
  const [isLoading, setLoading] = useState(false);
  const [isUpdating, setUpdating] = useState(false);


  useEffect(() => {
    startup();
  }, []);

  async function startup() {
    setLoading(true);
    let res = await Auth.currentSession();
    let idToken = res.getIdToken();
    let jwt = idToken.getJwtToken();
    const response = await axios({
      method: 'get',
      url: CONSTANTS.API_PATH + '/invitation_code',
      headers: {
        Authorization: "Bearer " + jwt
      }
    });

    // console.log(JSON.stringify(response.data));
    handleFieldChange({target: {
      id: "UPDATE_ALL",
      value: {
        code: response.data.code
      }
    }});
    setLoading(false);
  }


  async function submit(e) {
    e.preventDefault()
    e.stopPropagation()

    const form = e.currentTarget
    setFormValidated(true);
    if (form.checkValidity() === false) {
      return;
    }

    setUpdating(true);
    try {
      let res = await Auth.currentSession();
      let idToken = res.getIdToken();
      let jwt = idToken.getJwtToken();
      await axios({
        method: 'post',
        url: CONSTANTS.API_PATH + '/edit_invitation_code',
        headers: {
          Authorization: "Bearer " + jwt
        },
        data: {
          code: fields.code
        }
      });
      showNotification(1, "Invitation code updated!");
    } catch (error) {
      showNotification(0, error.message);
    }
    setUpdating(false);
  }

  function renderUpdateForm() {
    return (
      <CForm
        className="needs-validation d-grid"
        noValidate
        validated={formValidated}
        onSubmit={submit}
      >
        <CFormFloating>
          <CFormInput
            className="mb-4"
            type="text"
            id="code"
            placeholder="e.g. ASDFASDF"
            value={fields.code}
            onChange={handleFieldChange}
            minLength={1}
            disabled={isLoading || isUpdating}
            autoComplete="off"
            autoFocus
            required />
          <CFormLabel htmlFor="code">Invitation Code</CFormLabel>
        </CFormFloating>
        <LoaderButton
          type="submit"
          bssize="large"
          isLoading={isUpdating}
        >
          Update Invitation Code
        </LoaderButton>
      </CForm>
    );
  }

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Change Invitation Code</h1>
      </div>

      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 col-12">
              <p>Every staff registering to the portal will be asked this invitation code before they can submit the form.</p>
              { renderUpdateForm() }
            </div>
          </div>
        </div>
      </div>

    </div>

  )
}

export default ChangeEmail;